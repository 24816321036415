import React from "react";
import * as C from "./InputStyle";


// componente padrão de inputs fundo  tranparente font size 16px [12/08/2024]

const Input  = ({ type, placeholder, id, value, onChange, onBlur, autocomplete}) =>{
    return(
        <C.Input 
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
            id={id}
            onBlur={onBlur}
            autoComplete={autocomplete}
        />
    )
}


export default Input