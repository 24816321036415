import React, { useState, useEffect } from "react";
import Input from "../../../../components/inputs/Input";
import Button from "../../../../components/button/Button";
import * as C from "./AdvogadosSettingsStyles";
import { saveAdvogado, Logis } from "../../../../service/ServiceApi";

// Lista de siglas dos estados brasileiros
const estados = [
    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", 
    "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"
];

const AdvogadosSettings = ({ fullRoster, userRosterEdit, setUserRosterEdit, fetchData }) => {
    const [formData, setFormData] = useState({
        advogado: "",
        estado: "",
        oab: "",
        observacao: "",
        quemcadastrou: "",
        sociedade: "",
    });
    const [notificationMessage, setNotificationMessage] = useState("");

    useEffect(() => {
        if (Object.keys(userRosterEdit).length) {
            setFormData({
                advogado: userRosterEdit.advogado || "",
                estado: userRosterEdit.estado || "",
                oab: userRosterEdit.oab || "",
                observacao: userRosterEdit.observacao || "",
                quemcadastrou: userRosterEdit.quemcadastrou || "",
                sociedade: userRosterEdit.sociedade || "",
            });
        }
    }, [userRosterEdit]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
    };

    const handleSave = async () => {
        if (!formData.advogado || !formData.oab || !formData.sociedade) {
            return;
        }
    
        const existingAdvogado = fullRoster.find(
            (user) =>
                user.advogado.toLowerCase() === formData.advogado.toLowerCase() &&
                user.oab.toLowerCase() === formData.oab.toLowerCase()
        );

        if (existingAdvogado && Object.keys(userRosterEdit).length < 0) {
            if (existingAdvogado.ativo === 'N') {
                setNotificationMessage({
                    type: "error",
                    text: "Este advogado já existe, mas está desativado. Deseja reativá-lo?",
                    actions: [
                        {
                            label: "Reativar",
                            onClick: () => handleReactivarAdvogado(existingAdvogado)
                        },
                        {
                            label: "Cancelar",
                            onClick: () => setNotificationMessage("")
                        }
                    ]
                });
                setTimeout(() => {
                    setNotificationMessage("");
                }, 10000);
            } else {
                setNotificationMessage({
                    type: "error",
                    text: "Este advogado já está ativo no sistema."
                });
                setTimeout(() => {
                    setNotificationMessage("");
                }, 10000);
            }
            return;
        }
    
        const userToken = localStorage.getItem("user_token");
        let parsedUsername = '';
    
        if (userToken) {
            const parsedToken = JSON.parse(userToken);
            parsedUsername = parsedToken.user.username;
        }
    
        const updatedFormData = {
            ...formData,
            quemcadastrou: parsedUsername || formData.quemcadastrou,

            ...(Object.keys(userRosterEdit).length ? {} : {quemcadastrou: parsedUsername}),
            ...(Object.keys(userRosterEdit).length ? { id: userRosterEdit.id } : {})
        };
    
        let message = '';
        let logDescription = '';
    
        try {
            if (Object.keys(userRosterEdit).length) {
                logDescription = `Editado advogado ${updatedFormData.advogado}.`;
                message = "Informações do advogado editadas com sucesso!";
    
                // Atualiza o advogado e aguarda a conclusão
                await saveAdvogado(updatedFormData, true);
                
                // Recarrega os dados da tabela após a atualização
                await fetchData();
    
                // Limpa os campos do formulário após a atualização bem-sucedida
                setFormData({
                    advogado: "",
                    estado: "",
                    id: "",
                    oab: "",
                    observacao: "",
                    quemcadastrou: "",
                    sociedade: "",
                });
    
                setUserRosterEdit({});
    
                setNotificationMessage({ type: "success", text: message });
    
                // Registrar o log da ação
                Logis({
                    route: `/api/v1/advogados`,
                    descricao: logDescription,
                    user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                });
    
                setTimeout(() => {
                    setNotificationMessage("");
                }, 5000);
            } else {
                // Adicionando um novo advogado
                logDescription = `Novo advogado adicionado: ${updatedFormData.advogado}, OAB: ${updatedFormData.oab}, Sociedade: ${updatedFormData.sociedade}`;
                message = "Novo advogado adicionado com sucesso!";
    
                const response = await saveAdvogado(updatedFormData, false);
    
                // Verifica se a resposta contém a mensagem de erro
                if (response?.message === "Advogado já existe" && response?.status === "inativo") {
                    setNotificationMessage({
                        type: "error",
                        text: "Este advogado já existe, mas está desativado. Deseja reativá-lo?",
                        actions: [
                            {
                                label: "Reativar",
                                onClick: () => handleReactivarAdvogado(existingAdvogado)
                            },
                            {
                                label: "Cancelar",
                                onClick: () => setNotificationMessage("")
                            }
                        ]
                    });
                    return; // Não redefine os valores do formulário
                }
    
                // Recarrega os dados da tabela após adicionar o novo advogado
                await fetchData();
    
                // Limpa os campos do formulário após a adição bem-sucedida
                setFormData({
                    advogado: "",
                    estado: "",
                    id: "",
                    oab: "",
                    observacao: "",
                    quemcadastrou: "",
                    sociedade: "",
                });
    
                setNotificationMessage({ type: "success", text: message });
    
                // Registrar o log da ação
                Logis({
                    route: `/api/v1/advogados`,
                    descricao: logDescription,
                    user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                });
    
                setTimeout(() => {
                    setNotificationMessage("");
                }, 5000);
            }
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
    
            // Verifica se o erro é devido a advogado já existir
            if (error.response && error.response.data) {
                const { message, ativo } = error.response.data;
    
                // Verifica se o advogado já existe e está desativado
                if (message === "Advogado já existe" && ativo === "N") {
                    setNotificationMessage({
                        type: "error",
                        text: "Este advogado já existe, mas está desativado. Deseja reativá-lo?",
                        actions: [
                            {
                                label: "Reativar",
                                onClick: () => handleReactivarAdvogado(existingAdvogado)
                            },
                            {
                                label: "Cancelar",
                                onClick: () => setNotificationMessage("")
                            }
                        ]
                    });
                } else {
                    setNotificationMessage({ type: 'error', text: "Erro ao salvar advogado, tente novamente mais tarde." });
                }
            } else {
                setNotificationMessage({ type: 'error', text: "Erro ao salvar advogado, tente novamente mais tarde." });
            }
    
            // Registrar o log de erro
            Logis({
                route: `/api/v1/advogados`,
                descricao: `Erro ao salvar advogado ${updatedFormData.advogado}. Erro: ${error.message}`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
            });
    
            setTimeout(() => {
                setNotificationMessage("");
            }, 5000);
        }
    };

    const handleCancel = () => {
        setNotificationMessage("")
        setUserRosterEdit({});
        setFormData({
            advogado: "",
            estado: "",
            id: "",
            oab: "",
            observacao: "",
            quemcadastrou: "",
            sociedade: "",
        });
    };

    const handleReactivarAdvogado = async (advogado) => {
        try {
            // Atualize o advogado para 'ativo'
            const updatedAdvogado = { ...advogado, ativo: 'S' };
            await saveAdvogado(updatedAdvogado, true);
            await fetchData();
            setFormData({
                advogado: "",
                estado: "",
                id: "",
                oab: "",
                observacao: "",
                quemcadastrou: "",
                sociedade: "",
            });

            Logis({
                route: `/api/v1/advogados`,
                descricao: `reativando advogado ${advogado.advogado}`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                
            });
    
            setNotificationMessage({
                type: "success",
                text: "Advogado reativado com sucesso!"
            });
    
            setTimeout(() => {
                setNotificationMessage("");
            }, 5000);
        } catch (error) {
            console.error('Erro ao reativar advogado:', error);
            setNotificationMessage({
                type: "error",
                text: "Erro ao reativar advogado, tente novamente mais tarde."
            });

            Logis({
                route: `/api/v1/advogados`,
                descricao: `Erro ao reativar advogado ${advogado.advogado}`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                
            });

            setTimeout(() => {
                setNotificationMessage("");
            }, 5000);
        }
    };

    return (
        <C.UserSettingsContainer>
            <div>
                <C.UserSettingsButtonGroup>
                    <Button 
                        Text="Salvar"
                        type="button"
                        onClick={handleSave}
                    />
                    <Button 
                        Text="Cancelar"
                        type="button"
                        onClick={handleCancel}
                    />
                </C.UserSettingsButtonGroup>
                <C.UserSettingsTitle>Cadastro</C.UserSettingsTitle>
                <div>
                    <C.UserSettingsForm>
                        <C.UserSettingsFieldSet>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="advogado">Advogado</C.UserSettingsLabel>
                                <Input 
                                    type="text"
                                    value={formData.advogado}
                                    id="advogado"
                                    onChange={handleChange}
                                    autocomplete="on"
                                />
                            </C.UserSettingsField>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="oab">OAB/UF</C.UserSettingsLabel>
                                <Input 
                                    type="text"
                                    value={formData.oab}
                                    id="oab"
                                    onChange={handleChange}
                                    autocomplete="on"
                                />
                            </C.UserSettingsField>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="sociedade">Sociedade</C.UserSettingsLabel>
                                <Input 
                                    type="text"
                                    value={formData.sociedade}
                                    id="sociedade"
                                    onChange={handleChange}
                                    autocomplete="on"
                                />
                            </C.UserSettingsField>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="estado">Estado</C.UserSettingsLabel>
                                <C.UserSettingsSelect 
                                    value={formData.estado} 
                                    id="estado" 
                                    onChange={handleChange}
                                >
                                    <C.UserSettingsOption value="">.....</C.UserSettingsOption>
                                    {estados.map((estado) => (
                                        <C.UserSettingsOption key={estado} value={estado}>
                                            {estado}
                                        </C.UserSettingsOption>
                                    ))}
                                </C.UserSettingsSelect>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="observacao">Observações</C.UserSettingsLabel>
                                <Input 
                                    type="text"
                                    value={formData.observacao}
                                    id="observacao"
                                    onChange={handleChange}
                                    autocomplete="on"
                                />
                            </C.UserSettingsField>
                            </C.UserSettingsField>
                        </C.UserSettingsFieldSet>
                    </C.UserSettingsForm>
                </div>
            </div>
            {notificationMessage && (
                <C.NotificationMessage $type={notificationMessage.type}>
                    {notificationMessage.text}
                    {notificationMessage.actions && notificationMessage.actions.map((action, index) => (
                        <button key={index} onClick={action.onClick}>{action.label}</button>
                    ))}
                </C.NotificationMessage>
            )}
        </C.UserSettingsContainer>
    );
};

export default AdvogadosSettings;