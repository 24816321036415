import styled, { keyframes } from 'styled-components';

const l1 = keyframes`
    0%, 100% {
        box-shadow: 0 0 0 0 rgba(94, 83, 243, 0.5);
        width: 4.375rem; /* 70px */
        transform: rotate(0deg);
    }
    50% {
        box-shadow: 0 0 0 12.5rem rgba(94, 83, 243, 0.5); /* 200px */
        width: 18.75rem; /* 300px */
        transform: rotate(360deg);
    }
`;

export const LoaderPulseContainer = styled.div`
    width: 100%;
    height: 65vh;
    min-height:  18.75rem;
    border-radius: 1.25rem; /* 20px */
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LoaderPulseMax = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18.75rem; /* 300px */
    width: 18.75rem; /* 300px */
    border-radius: 50%;
    overflow: hidden;
`;

export const LoaderPulse = styled.div`
  width: 4.375rem; /* 70px */
  border: 0.3125rem dashed #857fd7; /* 5px */
  aspect-ratio: 1;
  border-radius: 50%;
  background: #655CF7;
  box-shadow: 0 0 0 0 #5e53f3;
  animation: ${l1} 3s infinite ease-in-out;
`;
