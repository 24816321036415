import React, { useState, useEffect } from "react";
import "../../../styles/variables.css";
import * as C from "./EmpresasStyles";

import { fetchEmpresas, removeEmpresas, Logis } from "../../../service/ServiceApi";
import EmpresasSetings from "./empresasSettings/EmpresasSettings";
import { TableRow } from "./rowTable/RowTableEmpresas";
import HeaderTable from "../../../components/table/headerTable/HeaderTable";
import SearchBar from "../../../components/search/Search";
import { FaAngleLeft, FaAnglesRight, FaAngleRight, FaAnglesLeft  } from "react-icons/fa6";



//tela de login sendo renderizada o tempo todo [12/08/2024]


const Empresas = ({hasSubPermission}) => {
    const [userRoster, setUserRoster] = useState([]);
    const [fullRoster, setFullRoster] = useState([]);
    const [userRosterEdit, setUserRosterEdit] = useState({});
    const [open, setOpen] = useState(false);
    const [openUserInfoIndex, setOpenUserInfoIndex] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isAscending, setIsAscending] = useState();
    const [activeSort, setActiveSort] = useState('empresa');

    const openCkick = () => {
        setOpen(prevState => !prevState);
    };

    const openSearchClick = () => {
        setOpenSearch(prevState => !prevState);
    };

    const fetchData = async () => {
        try {
            const data = await fetchEmpresas();
            setUserRoster(
                data
                    .filter((empresa) => empresa.ativo === "S")
                    .sort((a, b) => a.empresa.localeCompare(b.empresa, undefined, { sensitivity: 'base' }))
            );
            setFullRoster(data)
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRemove = async (empresa) => {
        try {
            await removeEmpresas(empresa);
            await fetchData();

            Logis({
                route: `/api/v1/empresas`,
                descricao: `removido advogado ${empresa.empresa} da lista`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                
            });

        } catch (error) {
            console.error('Erro ao remover o advogado:', error);
        }
    };

    const handleEditClick = (user) => {
        setUserRosterEdit(user);
        setOpen(true);
    };

    const toggleUserInfo = (index) => {
        setOpenUserInfoIndex(prevIndex => 
            prevIndex.includes(index) ? prevIndex.filter(i => i !== index) : [...prevIndex, index]
        );
    };

    const ordenePorChave = (chave, isDate = false) => {
        if (chave === activeSort) {
            // Alterna a direção de ordenação na mesma coluna
            setIsAscending((prev) => !prev);
        } else {
            // Define a nova coluna ativa e ordena de forma crescente por padrão
            setActiveSort(chave);
            setIsAscending((prev) => !prev);
        }
    
        setUserRoster((prevRoster) => {
            const sortedRoster = [...prevRoster].sort((a, b) => {
                if (isDate) {
                    const dateA = new Date(a[chave]);
                    const dateB = new Date(b[chave]);
                    return isAscending ? dateA - dateB : dateB - dateA;
                }
                return isAscending 
                    ? a[chave].localeCompare(b[chave], undefined, { sensitivity: 'base' }) 
                    : b[chave].localeCompare(a[chave], undefined, { sensitivity: 'base' });
            });
            return sortedRoster;
        });
    };

    const ordeneName = () => ordenePorChave('empresa');
    const ordeneCNPJ = () => ordenePorChave('cnpj');
    const ordeneStatus = () => ordenePorChave('status');
    const ordeneData = () => ordenePorChave('dtcadastro', true);

    const headers = [
        { label: "Data", onClick: ordeneData, key: "dtcadastro"},
        { label: "Nome", onClick: ordeneName, key: "empresa" },
        { label: "CNPJ", onClick: ordeneCNPJ, key: "cnpj" },
        { label: "Status", onClick: ordeneStatus, key: "status" },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 100;

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };
    
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const generatePageNumbers = () => {
        const maxPageNumbers = 5; // Quantidade máxima de números de página a serem exibidos
        let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
        let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
    
        if (endPage - startPage < maxPageNumbers - 1) {
            startPage = Math.max(1, endPage - maxPageNumbers + 1);
        }
    
        return [...Array(endPage - startPage + 1)].map((_, i) => startPage + i);
    };

    useEffect(() => {
        const filtered = userRoster.filter(user =>
            (user.empresa?.toLowerCase().includes(searchTerm.toLowerCase()) || 
             user.cnpj?.toLowerCase().includes(searchTerm.toLowerCase()) || 
             '')
        );
        setFilteredUsers(filtered);
        setCurrentPage(1); // Reset to first page on new search
    }, [searchTerm, userRoster]);

    return (
        <C.UsuariosContainer>
            <C.UsuariosSection>
                {!openSearch && (
                    <C.SearchBar>
                        <SearchBar
                            onSearch={setSearchTerm}
                            onOpenSearch={openSearchClick}
                            placeholder={"Busca pela empresa ou CNPJ"}
                            openSearch={openSearch}
                        />
                    </C.SearchBar>
                )}
                <HeaderTable
                    headers={headers}
                    columns={headers.length+1}
                    data={currentUsers}
                    activeColumn = { activeSort }
                    isAscending = { isAscending } 
                    onButtonClick={openCkick}
                    onOpenSearch={openSearchClick}
                    hasBtn={hasSubPermission(4,8)}
                    openSearch={openSearch}
                    renderRow={(user, index) => {
                        const userPageIndex = `${user.id}`;
                        return (
                            <TableRow
                                key={userPageIndex}
                                empresas={user}
                                index={index}
                                isOpen={openUserInfoIndex.includes(userPageIndex)}
                                toggleUserInfo={() => toggleUserInfo(userPageIndex)}
                                handleEditClick={handleEditClick}
                                handleRemove={handleRemove}
                                hasSubPermission = {hasSubPermission}
                            />
                        );
                    }}
                />
                                {totalPages > 1 && (
                    <C.PaginationContainer>
                        <C.PaginationButton
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                        >
                            <FaAnglesLeft />
                        </C.PaginationButton>
                        <C.PaginationButton
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            <FaAngleLeft />
                        </C.PaginationButton>
                        {generatePageNumbers().map((pageNumber) => (
                            <C.PaginationButton
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                $active={pageNumber === currentPage}
                            >
                                {pageNumber}
                            </C.PaginationButton>
                        ))}
                        <C.PaginationButton
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            <FaAngleRight />
                        </C.PaginationButton>
                        <C.PaginationButton
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                        >
                            <FaAnglesRight />
                        </C.PaginationButton>
                    </C.PaginationContainer>
                )}
            </C.UsuariosSection>
            {open && hasSubPermission(4,8) &&(
                <EmpresasSetings 
                    companyRoster={userRoster}
                    fullRoster = { fullRoster }
                    setcompanyRoster={setUserRoster}
                    companyRosterEdit={userRosterEdit}
                    setCompanyRosterEdit={setUserRosterEdit}
                    fetchData={fetchData}
                />
            )}
        </C.UsuariosContainer>
    );
};

export default Empresas;