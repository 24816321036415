import React, { useState } from 'react';
import { BsSearch } from "react-icons/bs";
import * as C from './SearchStyles';
import '../../styles/variables.css';
import Input from '../inputs/Input';

//componente de busca retorna o valor dentro do input de busca, recebe um controlador de estado e um controlador de saida
// [12/08/2024]

const SearchBar = ({ onSearch, isExiting, placeholder }) => {
    const [searchTerm, setSearchTerm] = useState('');
    

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
        onSearch(event.target.value);
    };

    //foca no input caso o usuario clicar no icone de lupa [12/08/2024]

    const handleSearchClick = () => {
        const input = document.getElementById('input_search_term');
        if (input) {
            input.focus();
        }
    };

    return (
        <C.SearchContainer $isExiting={isExiting}>
            <C.BeSearch onClick={handleSearchClick}>
                <BsSearch />
            </C.BeSearch>
            <Input
                type="text"
                value={searchTerm}
                onChange={handleChange}
                id="input_search_term"
                placeholder= { placeholder }
            />
        </C.SearchContainer>
    );
};

export default SearchBar;