import styled from "styled-components";

export const NavbarContainer = styled.nav`
    display: flex;
    flex-direction: column;
    min-width: 12.5rem; /* 200px */
    min-height: 28.125rem; /* 450px */
    padding: 1.5rem 1.625rem; /* 24px 26px */
    background-color: var(--color-grayBlue);
    border-radius: 0 1.25rem 1.25rem 0; /* 20px */
    z-index: 200;
    user-select: none; /* Para navegadores modernos */
    -webkit-user-select: none; /* Para Safari */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para Internet Explorer/Edge */

    @media (max-width: 32.5rem) { /* 520px */
      width: 16.25rem; /* 260px */
      position: absolute;
      top: 3.5rem; /* 56px */
      height: calc(100vh - 3.5rem); /* 100vh - 56px */
    }
`;

export const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem; /* 16px */
`;

export const NavLink = styled.div`
  color: var(--color-black);
  align-items: center;
  border-radius: 1.25rem; /* 20px */
  text-decoration: none;
  font-size: 1rem; /* 16px */
  display: flex;
  gap: 0.5rem; /* 8px */
  padding: 0 1rem; /* 16px */
  min-height: 2.5rem; /* 40px */
  cursor: pointer;

  svg {
    font-size: 1.125rem; /* 18px */
    fill: var(--color-black);
    height: 100%;
  }

  &:hover {
    color: var(--color-white);
    background: var(--color-primary);

    svg {
        fill: var(--color-white);
    }
  }
`;

export const ImgContainer = styled.div`
    display: flex;
    align-items: center;
    height: 6.25rem; /* 100px */
    max-width: 11.25rem; /* 180px */
    margin-top: auto;

    img {
        width: 100%;
        height: auto;
    }
`;
