import React from 'react';
import { AiFillCaretRight, AiFillCaretDown } from "react-icons/ai";
import * as C from '../EmpresasStyles';

//componente do corpo da tabela expansiva para mais obter mais informações usuario

const TableRow = ({ empresas, index, isOpen, toggleUserInfo, handleEditClick, handleRemove, hasSubPermission }) => (
    <>
        <C.UsuariosTr onClick={() => toggleUserInfo(index)}>
            <C.UsuariosTd>{new Date(empresas.dtcadastro).toLocaleDateString('pt-BR')}</C.UsuariosTd>
            <C.UsuariosTd>{empresas.empresa}</C.UsuariosTd>
            <C.UsuariosTd>{empresas.cnpj}</C.UsuariosTd>
            <C.UsuariosTd>
            {empresas.status === 'I' ? 'Insolvente' : empresas.status === 'S' ? 'Solvente' : 'Status Desconhecido'}
            </C.UsuariosTd>
            <C.UsuariosToggleIcon>
                {isOpen ? <AiFillCaretDown /> : <AiFillCaretRight />}
            </C.UsuariosToggleIcon>
        </C.UsuariosTr>
        {isOpen && (
            <C.UsuariosUserInfoTr>
                <C.UsuariosInfoTd>
                <UserDetails
                        empresas={empresas}
                        handleEditClick={() => handleEditClick(empresas)}
                        handleRemove={() => handleRemove(empresas)}
                        hasSubPermission = {hasSubPermission}
                    />
                </C.UsuariosInfoTd>
            </C.UsuariosUserInfoTr>
        )}
    </>
);

const UserDetails = ({ empresas, handleEditClick, handleRemove, hasSubPermission}) => (
    <C.UsuariosUserInfo>
        <C.UsuariosUserDetail><strong>Nome:</strong> {empresas.empresa}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>CNPJ:</strong> {empresas.cnpj}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>Status:</strong>{empresas.status === 'I' ? 'Insolvente' : empresas.status === 'S' ? 'Solvente' : 'Status Desconhecido'} </C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>Data de inclusão:</strong>{new Date(empresas.dtcadastro).toLocaleDateString('pt-BR')}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>Cadastrado por:</strong> {empresas.quemcadastrou}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>Observação:</strong> {empresas.observacao}</C.UsuariosUserDetail>
        {hasSubPermission(4,8) &&
        <C.UsuariosEditDeleteContainer>
            <C.UsuariosEditButton onClick={handleEditClick}>Editar</C.UsuariosEditButton>
            <C.UsuariosDeleteButton onClick={handleRemove}>Deletar</C.UsuariosDeleteButton>
        </C.UsuariosEditDeleteContainer>}
    </C.UsuariosUserInfo>
);

export { TableRow, UserDetails };