import styled, { keyframes } from 'styled-components';

const l3 = keyframes`
  to { transform: rotate(1turn); }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 65vh;
  min-height:  18.75rem;
`;

export const LoaderSpin = styled.div`
  width: 50%;
  max-width: 28.125rem; /* 450px */
  padding: 0.625rem; /* 10px */
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--color-primary);
  --_m: 
    conic-gradient(transparent 10%, var(--color-black)),
    linear-gradient(var(--color-black) 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: ${l3} 1s infinite linear;
`;