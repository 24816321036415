import styled from "styled-components";


export const UsuariosContainer = styled.div`
    display: flex;
    gap: 10px;
    height: 100%;

    @media (max-width: 800px) {
        padding: 0;
        justify-content: center;
        gap:24px;
        flex-wrap: wrap;

    }
`;

export const UsuariosSection = styled.section`
    flex: 1 auto;
    background: var(--color-grayBlue);
    padding: 20px;
    border-radius: 20px;
    height: 100%;
    width: 80%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

`;

export const SearchBar = styled.div`
    min-width: 550px;
`

export const UsuariosTable = styled.table`
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    flex-grow: 1;  
    height: 100%;
    min-width: 550px;
`;

export const UsuariosThead=styled.tr`
    display: grid;
    grid-template-columns: ${({ $columns }) => `repeat(${$columns}, 1fr)`};
    text-align: left;
    align-items: center;
    background: var(--color-black);
    color: var(--color-white);
    border-radius: 20px;
    padding: 0 12px;

`;

export const UsuariosTh = styled.th`
    padding: 8px 12px;
    cursor: pointer;
`;

export const UsuariosThPlus = styled.th`
    display: flex;
    justify-content: flex-end;
    padding: 8px 8px 8px 8px;
    gap: 8px;


    button{
        font-size: 20px;
        width: 30px;
        height: 30px;
        color: var(--color-black);
        background: var(--color-white);
    }

    svg{
        font-size: 16px;
    }
`;

export const UsuariosTbody = styled.tbody`
    
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    gap: 24px;
    margin: 12px 0px 12px 0 ;
    flex-grow: 1;  /* Makes sure it takes available vertical space */
    padding-bottom: 120px;

    &::-webkit-scrollbar {
    width: 0; 
  }
`;

export const UsuariosTr = styled.tr`
    background: var(--color-white);
    width: 100%;
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    cursor: pointer;
    padding: 0 12px;
    border-radius: 20px;
    z-index: 1;
`;

export const UsuariosUserInfoTr = styled.tr`
    width: 100%;
    background: var( --color-gray);
    position: relative;
    top: -45px;
    border-radius: 0px 0px 20px 20px;
`;

export const UsuariosTd = styled.td`
    padding: 8px 12px;
    text-align: left;
    word-break: break-word;
    flex-shrink: 1; 
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
`;

export const UsuariosToggleIcon = styled.td`
    padding: 8px;
    padding-right: 15px;
    display: flex;
    justify-content: flex-end;
    
`;

export const UsuariosInfoTd = styled.td`
    padding: 8px 12px;
    text-align: left;
    display: contents;
`;

export const UsuariosUserInfo = styled.div`
    padding: 20px;
    margin-top: 24px;
    border-radius: 0 0 20px 20px;

`;

export const UsuariosUserDetail = styled.div`
    margin-bottom: 10px;
    font-size: 14px;
    color: var(--color-darkGray);
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: calc(100% - 40px);

    strong {
        color: var(--color-primary);
        word-break: break-word;
        flex-shrink: 1; 
        overflow: hidden;
        text-overflow: ellipsis; 
        white-space: nowrap; 
    }
`;

export const UsuariosEditDeleteContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
    margin-top: 15px;
    border-top: 1px solid var(--color-lightGray);
    padding-top: 10px;
`;

export const UsuariosEditButton = styled.div`
    cursor: pointer;
    color: var(--color-primary);
    transition: color 0.3s;
`;

export const UsuariosDeleteButton = styled.div`
    cursor: pointer;
    color: var(--color-danger);
    transition: color 0.3s;
`;

export const PaginationContainer = styled.div`
    position: absolute;
    bottom: 0; 
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 1;
    padding: 16px;
    min-width: 570px;
    background-color: var(--color-grayBlue);
`;

export const PaginationButton = styled.button`
    margin: 0 5px;
    padding: 8px 16px;
    background-color: ${({ $active }) => ($active ? 'var(--color-primary)' : 'var(--color-white)')};
    color: ${({ $active }) => ($active ? 'var(--color-white)' : 'var(color-black)')};
    border: none;
    border-radius: 10px;
    cursor: pointer;

    &:disabled {
        background-color: var(--color-lightGray);
        cursor: not-allowed;
    }
`;