import styled from 'styled-components';

export const TimelineContainer = styled.div`
    background: var(--color-light-gray);
    padding: 1.25rem; /* 20px */
    border-radius: 0.625rem; /* 10px */
    display: flex;
    flex-direction: column;
    gap: 0.625rem; /* 10px */
    min-height: 42.5rem; /* 680px */
`;

export const MovSearchContainer = styled.div`
    div {
        min-width: 0;
    }
`;

export const TimelineItem = styled.div`
    position: relative;
    padding-left: 1.25rem; /* 20px */
    margin-bottom: 0.625rem; /* 10px */
    border-left: 0.125rem solid var(--color-primary); /* 2px */
`;

export const Date = styled.p`
    font-size: 0.75rem; /* 12px */
    color: var(--color-dark-gray);
    margin: 0 0 0.3125rem 0; /* 0 0 5px 0 */
`;

export const Description = styled.p`
    font-size: 0.875rem; /* 14px */
    color: var(--color-dark);
    margin: 0;
`;
