import styled from "styled-components";


export const Button = styled.button`
    padding: 0.3125rem 0; /* 5px */
    outline: none;
    border: none;
    border-radius: 1.25rem; /* 20px */
    width: 100%;
    cursor: pointer;
    background-color: var(--color-primary);
    color: var(--color-white);
    font-weight: 600;
    font-size: 1rem; /* 16px */
`;