import styled, { keyframes } from 'styled-components';

const slideDown = keyframes`
  from {
    transform: translateY(-1.25rem); /* -20px */
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
    border-radius: 0;
  }
  70% {
    transform: translate(31.25rem, 3.5rem); /* 500px, 56px */
    opacity: 0;
  }
  100% {
    transform: translateY(43.75rem); /* 700px */
    opacity: 1;
  }
`;

export const SearchContainer = styled.div`
    margin-bottom: 1.25rem; /* 20px */
    display: flex;
    justify-content: center;
    min-width: ${({ $isExiting }) => ($isExiting ? "none" : "23.125rem")}; /* 370px */
    border: 1px solid var(--color-white);
    box-shadow: 0px 0.125rem 0.3125rem rgba(0, 0, 0, 0.1); /* 2px 5px */
    background: var(--color-white);
    border-radius: ${({ $isExiting }) => ($isExiting ? "31.25rem" : "1.25rem")}; /* 500px, 20px */
    width: ${({ $isExiting }) => ($isExiting ? "1.875rem" : "100%")}; /* 30px */
    align-items: center;
    animation: ${({ $isExiting }) => ($isExiting ? slideUp : slideDown)} 0.4s ease forwards;

    /* Ocultar o input quando a animação de saída está em andamento */
    input {
        padding: 0.625rem; /* 10px */
        font-size: 1rem; /* 16px */
        border-left: 1px solid var(--color-gray);
        border-radius: 0 1.25rem 1.25rem 0; /* 20px */
        opacity: ${({ $isExiting }) => ($isExiting ? 0 : 1)};
        display: ${({ $isExiting }) => ($isExiting ? "none" : "flex")};
        transition: opacity 0.2s ease;
    }
`;

export const BeSearch = styled.div`
    padding: 0 0.75rem; /* 12px */
    cursor: text;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.4s ease, width 0.4s ease;
`;
