import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.625rem; /* 10px */
    height: 100vh;
    min-width: 31.25rem; /* 500px */
    background: linear-gradient(50deg, var(--color-secondary) 0%, var(--color-primary) 100%) no-repeat;

    @media(max-width: 700px){
        min-width: 0;
    }
`;

export const Content = styled.div`
    gap: 0.9375rem; /* 15px */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0.25rem 0.25rem 0.25rem var(--color-border); /* 4px */
    background-color: white;
    padding: 1.25rem; /* 20px */
    border-radius: 1.25rem; /* 20px */
    margin: 0 auto;
    min-width: 25rem; /* 400px */
    min-height: 25rem; /* 400px */
    background: var(--color-white);
    z-index: 10;

    input {
        border: 0.0625rem solid var(--color-neutral); /* 1px */
        padding: 0.5rem; /* 8px */
        color: var(--color-neutral);
        background: transparent;
        border-radius: 1.25rem; /* 20px */
    }

    button {
        padding: 0.9375rem 0; /* 15px 0 */
    }

    @media(max-width: 700px){
        max-width: 350px;
        min-width: 0;
    }

    @media(max-width: 350px){
        max-width: 250px;
    }

    
`;

export const Title = styled.h1`
    color: var(--color-neutral);
    text-align: center;

    @media(max-width: 350px){
        font-size: 24px;
        }
`;

export const Paragraf = styled.div`
    color: var(--color-neutral);
    font-size: 0.75rem; /* 12px */
    text-align: center;
`;

export const Background = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 0;
`;

export const BackgroundCircle1 = styled.div`
    height: 18.75rem; /* 300px */
    width: 18.75rem; /* 300px */
    border-radius: 50%;
    background: var(--color-variant-blue);
    position: absolute;
    bottom: -9.375rem; /* -150px */
    left: -9.375rem; /* -150px */
    z-index: 1;

    @media (min-width: 87.5rem) { /* 1400px */
        height: 26.25rem; /* 420px */
        width: 26.25rem; /* 420px */
        bottom: -9.375rem; /* -150px */
        left: -9.375rem; /* -150px */
    }
`;

export const BackgroundCircle2 = styled.div`
    height: 18.75rem; /* 300px */
    width: 18.75rem; /* 300px */
    border-radius: 50%;
    background-color: var(--color-variant-blue);
    position: absolute;
    left: 50%;
    top: 1.25rem; /* 20px */
    z-index: 1;

    @media (min-width: 87.5rem) { /* 1400px */
        height: 26.25rem; /* 420px */
        width: 26.25rem; /* 420px */
        top: 0.9375rem; /* 15px */
        left: 52%;
        transform: translateX(-50%);
    }

    @media(max-width: 700px){
        display: none;
    }
`;

export const BackgroundCircle3 = styled.div`
    height: 25rem; /* 400px */
    width: 25rem; /* 400px */
    border-radius: 50%;
    background-color: var(--color-variant-blue);
    position: absolute;
    right: -1.25rem; /* -20px */
    top: -6.25rem; /* -100px */
    z-index: 1;

    @media (min-width: 87.5rem) { /* 1400px */
        height: 45.625rem; /* 730px */
        width: 45.625rem; /* 730px */
        right: 0;
        top: -12.5rem; /* -200px */
    }
`;

export const Strong = styled.strong`
    cursor: pointer;
    align-self: flex-start;

    p {
        font-size: 0.75rem; /* 12px */
        text-decoration-line: underline;
        text-decoration-style: solid;
        color: var(--color-neutral);
        background: var(--color-white);
        margin: 0.3125rem; /* 5px */
    }
`;

export const FormAcess = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.25rem; /* 20px */  
`;

export const Img = styled.div`
    width: 100%;
    height: auto;
    display: contents;

    svg {
        font-size: 7.75rem; /* 124px */
        color: #575555;
    }

    @media(max-width: 550px){
        svg{
            font-size: 100px;
        }
    }

    @media(max-width: 350px){
        svg{
            font-size: 85px;
        }
    }
`;

export const ErrorMessage = styled.p`
    color: red;
`;
