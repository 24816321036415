import styled from 'styled-components';


export const CardContainer = styled.div`
    padding: 0 12px;
    `;

export const Card = styled.div`
    background-color: var(--color-white);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    transition: transform 0.2s ease;
    margin: 18px 0;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);

        span{
            color: var(--color-primaryDark);
        }
    }
`;

export const NomeRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    max-width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 47%;

    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
`;

export const NomeContainer = styled.div`
    margin-bottom: 6px;
    display: flex;
    gap: 6px;
    flex-direction: column;
    max-width: 100%;
    text-overflow: ellipsis;
`;

export const ProcessoContainer = styled.div`
    margin-bottom: 12px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
`;

export const Label = styled.div`
    font-weight: bold;
    margin-right: 8px;
    color: var(--color-darkGray);
`;

export const Nome = styled.span`
    font-size: 16px;
    color: var(--color-neutral);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: lighter;
    max-width: 100%;
`;

export const CPF = styled.span`
    font-size: 16px;
    font-weight: lighter;
    color: var(--color-neutral);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

export const CNPJ = styled.span`
    font-size: 16px;
    font-weight: lighter;
    color: var(--color-neutral);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

export const ProcessoItem = styled.span`
    font-size: 16px;
    color: var(--color-neutral);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-weight: bold;

`;

export const Divider = styled.div`
    margin: 12px 0;
    border-top: 1px solid #e0e0e0;
`;

export const DividerVertical = styled.div`
    width: 1px;
    background-color: #e0e0e0;
    margin: 0 12px;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const ItemCount= styled.div`
    position: relative;
    top: -56px;
    right: 0;
    margin: 24px 0;           
    font-size: 10px;        
    color: var(--color-darkGray);           
    display: flex;
    justify-content: flex-end;
    cursor: text;
`
