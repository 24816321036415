import styled from "styled-components";

export const UserSettingsContainer = styled.div`
    padding: 20px;
    background: var(--color-grayBlue);
    border-radius: 20px;
    height: 100%;
    overflow: auto;
    width: 100%;
    max-width: 300px;
`;

export const UserSettingsButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 12px;

    button{
        border-radius: 5px;
    }
`;

export const UserSettingsPrevsButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const UserSettingsTitle = styled.h2`
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 12px;
`;

export const UserSettingsForm = styled.form`
    display: flex;
    flex-direction: column;

    input{
        padding: 10px;
        border: 1px solid var(--color-border);
        border-radius: 10px;
        margin-top: 5px;
        background: var(--color-white);
    }
`;

export const UserSettingsFieldSet = styled.fieldset`
    display: block;
    border: none;
    padding: 0;
    
`;

export const UserSettingsField = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`;

export const UserSettingsLabel = styled.label`
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
`;

export const UserSettingsSelect = styled.select`
    padding: 10px;
    font-size: 1rem;
    border-radius: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    margin-bottom: 12px;
    cursor: pointer;
    appearance: none; /* Oculta a seta padrão */
    -webkit-appearance: none; /* Para Safari */
    -moz-appearance: none; /* Para Firefox */

    &:focus {
        outline: none;
        border-color: #ccc;
    }

`;

export const UserSettingsOption = styled.option`
    background-color: #fff;
    color: #333;

    &:hover,
    &:focus {
        background-color: none;
        border-color: none;
    }
`;

export const UserSettingsInput = styled.input`
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s;
`;

export const  NotificationMessage  = styled.div`
    margin-top: 10px;
    padding-left: 12px;
    color: ${({ $type }) => 
        $type === 'success' ? 'green' : 
        $type === 'error' ? 'red' : 
        'transparent'
    };

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        color: white;
        background: var(--color-primary);
        margin-right: 6px;
        margin-top: 6px;
        }
`;
