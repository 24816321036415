import styled from "styled-components";

export const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const MainContent = styled.main`
  display: flex;
  flex: 1 1;
  width: 100%;
  overflow: hidden;
  min-height: 30.625rem; /* 490px */

  @media (max-width: 32.5rem) {
      min-height: 450px;
    }
`;

export const ArticleContent = styled.article`
  padding: 1rem;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  min-height: 95%;
  min-width: 20rem; /* 320px */
`;

export const SearchContainer = styled.form`
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    min-height: 6.25rem; /* 100px */
`;
