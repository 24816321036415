import React from "react";
import * as C from "./HeaderStyle";
import "../../styles/variables.css";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";

import LMLogo from "../../assets/images/LMLogo.jpeg";
import { AiFillBell } from "react-icons/ai";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import { FaBars } from "react-icons/fa6";
import { BsAppIndicator } from "react-icons/bs";

import useAuth from "../../hooks/useAuth";



const Header = ({ Text, onHamburgerClick, onAppIndicatorClick }) =>{

    const { userExit } = useAuth(); // pega as funções em hooks useAuth

    const navigate = useNavigate();
    const userToken = localStorage.getItem("user_token");
    let parsedUsername = ''; // variavel para pegar o nome do usuario

    // retorna o nome do usuario na tela 
    
    if (userToken) {
        const parsedToken = JSON.parse(userToken);
        parsedUsername = parsedToken.user.nome;
    }

    return(
        <C.HeaderBody>
            <C.HamburgerBox>
                <C.ImgContainer>
                    <img src={LMLogo} alt="logo"></img>
                </C.ImgContainer>
                <C.HamburgerIcon onClick={onHamburgerClick}>
                    <FaBars/>
                </C.HamburgerIcon>
            </C.HamburgerBox>
                <C.HeaderTitle>{ Text }</C.HeaderTitle>
            <C.UserBody> 
                <C.UserRef>
                    { parsedUsername }
                </C.UserRef>
                <AiFillBell />
                <C.HamburgerIcon onClick={onAppIndicatorClick}>
                    <BsAppIndicator />
                </C.HamburgerIcon>
                <Button 
                Text= { <FaArrowRightFromBracket />}
                type= "button"
                onClick={() => [userExit(), navigate("/")]}
                />  
            </C.UserBody>
        </C.HeaderBody>
    );
};


export default Header