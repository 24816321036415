import React from "react";
import * as C from "./ListProcessStyle";
import "../../styles/variables.css";


// componete que recebe uma string de processo e um controlador de estado, retorna uma lista dos processos
// possivel refazer requisição ao usuario dar dbclick [12/08/2024]

const ListProcess = ( { history, handleHistoryClick } ) =>{


    return (

            <C.HistoryContainer>
                <C.HistoryTitle>Histórico</C.HistoryTitle>
                <C.HistoryBox>
                    {history.map((processo, index) => (
                        <C.HistoryItem key={index} onClick={() => handleHistoryClick(processo)}>
                            <C.NameTitle>
                                <C.HistoryName>{ processo.name.NomeAtivo }</C.HistoryName>
                                <C.HistoryIndex><p>{index+1}</p></C.HistoryIndex>
                            </C.NameTitle>
                            <C.HistoryProcessTitle>Número do Processo:</C.HistoryProcessTitle>
                            <C.HistoryText> {processo.NumProcesso} </C.HistoryText>
                            <C.FetchTime>Atualizado em: {processo.fetchTime}</C.FetchTime>
                        </C.HistoryItem>
                    ))}
                </C.HistoryBox>
            </C.HistoryContainer>


    );
};

export default ListProcess;