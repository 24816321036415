import { createGlobalStyle } from "styled-components";


// styles global [17/07/2024] 

const GlobalStyle = createGlobalStyle`

    *{

        margin: 0;
        padding : 0;
        box-sizing: border-box;
    }

    html{
        min-width: 320px;
    }

    body{
        width: 100%;
        height: 100vh;
        font-family: Arial, sans-serif;
        overflow: auto;
    }


`;


export default GlobalStyle;