import React, { useState, useEffect } from "react";
import "../../../styles/variables.css";
import * as C from "./UsuariosStyles";

import { fetchUsers, removeUser, Logis } from "../../../service/ServiceApi";
import UserSettings from "./userSettings/UserSettings";
import { TableRow } from "./rowTable/RowTableUsuarios";
import HeaderTable from "../../../components/table/headerTable/HeaderTable";
import SearchBar from "../../../components/search/Search";
import { FaAngleLeft, FaAnglesRight, FaAngleRight, FaAnglesLeft  } from "react-icons/fa6";


//componente de renderização dos usuarios


const Usuarios = () => {
    const [userRoster, setUserRoster] = useState([]);
    const [userRosterEdit, setUserRosterEdit] = useState({});
    const [open, setOpen] = useState(false);
    const [openUserInfoIndex, setOpenUserInfoIndex] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isAscending, setIsAscending] = useState();
    const [activeSort, setActiveSort] = useState('Ap_Nome');
  


    // controlador de estado para tela de cadastro

    const openCkick = () => {
        setOpen(prevState => !prevState);
    };
    

    //controlador de estado para o filtro de procura

    const openSearchClick = () =>{
        setOpenSearch(prevState => !prevState)
        
    } 

    // função para ordenar nome 

    const fetchData = async () => {
        try {
            const data = await fetchUsers();
            
            setUserRoster(
                data.usuarios_ativos.sort((a, b) => 
                    a.Ap_Nome.localeCompare(b.Ap_Nome, undefined, { sensitivity: 'base' })
                )
            );
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // função remover usuario

    const handleRemove = async (user) => {
        try {
            await removeUser(user);
            await fetchData();

            Logis({
                route: `/api/v1/users`,
                descricao: `removido ${user.Ap_Nome} da lista`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                
            });

        } catch (error) {
            console.error('Erro ao remover o usuario:', error);
        }
    };

    // função para editar tabela

    const handleEditClick = (user) => {
        setUserRosterEdit(user);
        setOpen(true);
    };

    // abre a tela de cadastro caso não esteja e manda os dados do usuario selecionado para ser editada

    const toggleUserInfo = (userPageIndex) => {
        if (openUserInfoIndex.includes(userPageIndex)) {
            setOpenUserInfoIndex(openUserInfoIndex.filter(id => id !== userPageIndex));
        } else {
            setOpenUserInfoIndex([...openUserInfoIndex, userPageIndex]);
        }
    };

    // função para ordenar nome quando o usuario clica no nome

    const ordenePorChave = (chave, isDate = false) => {
        if (chave === activeSort) {
            // Alterna a direção de ordenação na mesma coluna
            setIsAscending((prev) => !prev);
        } else {
            // Define a nova coluna ativa e ordena de forma crescente por padrão
            setActiveSort(chave);
            setIsAscending((prev) => !prev);
        }
    
        setUserRoster((prevRoster) => {
            const sortedRoster = [...prevRoster].sort((a, b) => {
                if (isDate) {
                    const dateA = new Date(a[chave]);
                    const dateB = new Date(b[chave]);
                    return isAscending ? dateA - dateB : dateB - dateA;
                }
                return isAscending 
                    ? a[chave].localeCompare(b[chave], undefined, { sensitivity: 'base' }) 
                    : b[chave].localeCompare(a[chave], undefined, { sensitivity: 'base' });
            });
            return sortedRoster;
        });
    };
    
    // Função para ordenar o nome
    const ordeneName = () => ordenePorChave('Ap_Nome');
    const ordeneEmail = () => ordenePorChave('Ap_Email');
    const ordeneUsername = () => ordenePorChave('Ap_User');
    
    // Configuração das colunas no header
    const headers = [
        { label: "Nome", onClick: ordeneName, key: 'Ap_Nome'},
        { label: "Username", onClick: ordeneUsername, key: 'Ap_User'},
        { label: "Email", onClick: ordeneEmail, key: 'Ap_Email' },
    ];
    



    
    // cria paginação para usuarios
    
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 20;
    
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
    
    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };
    
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const generatePageNumbers = () => {
        const maxPageNumbers = 5; // Quantidade máxima de números de página a serem exibidos
        let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
        let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
    
        if (endPage - startPage < maxPageNumbers - 1) {
            startPage = Math.max(1, endPage - maxPageNumbers + 1);
        }
    
        return [...Array(endPage - startPage + 1)].map((_, i) => startPage + i);
    };

    // função que retorna a lista de usuarios filtradas [12/08/2024]
    
    useEffect(() => {
        const filtered = userRoster.filter(user => {
            const nome = user.Ap_Nome?.toLowerCase() || '';
            const username = user.Ap_username?.toLowerCase() || '';
            const email = user.Ap_email?.toLowerCase() || '';
    
            return nome.includes(searchTerm.toLowerCase()) ||
                   username.includes(searchTerm.toLowerCase()) ||
                   email.includes(searchTerm.toLowerCase());
        });
        setFilteredUsers(filtered);
        setCurrentPage(1); 
    }, [searchTerm, userRoster]);


    return (
        <C.UsuariosContainer>
            <C.UsuariosSection>
                {openSearch && (
                    <C.SearchBar>
                        <SearchBar
                            onSearch={setSearchTerm}
                            onOpenSearch={openSearchClick}
                            placeholder={"Busca por nome, username ou email"}
                            openSearch={openSearch}
                        />
                    </C.SearchBar>
                )}
                <HeaderTable
                    headers={headers}
                    columns={headers.length + 1}
                    data={currentUsers}
                    activeColumn = { activeSort }
                    isAscending = { isAscending }
                    onButtonClick={openCkick}
                    onOpenSearch={openSearchClick}
                    hasBtn={true}
                    openSearch={openSearch}
                    renderRow={(user, index) => {
                        const userPageIndex = `${user.Ap_Id}`;
                        return (
                            <TableRow
                                key={userPageIndex}
                                user={user}
                                index={index}
                                isOpen={openUserInfoIndex.includes(userPageIndex)}
                                toggleUserInfo={() => toggleUserInfo(userPageIndex)}
                                handleEditClick={handleEditClick}
                                handleRemove={handleRemove}
                            />
                        );
                    }}
                />
                {totalPages > 1 && (
                    <C.PaginationContainer>
                        <C.PaginationButton
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                        >
                            <FaAnglesLeft />
                        </C.PaginationButton>
                        <C.PaginationButton
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            <FaAngleLeft />
                        </C.PaginationButton>
                        {generatePageNumbers().map((pageNumber) => (
                            <C.PaginationButton
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                $active={pageNumber === currentPage}
                            >
                                {pageNumber}
                            </C.PaginationButton>
                        ))}
                        <C.PaginationButton
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            <FaAngleRight />
                        </C.PaginationButton>
                        <C.PaginationButton
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                        >
                            <FaAnglesRight />
                        </C.PaginationButton>
                    </C.PaginationContainer>
                )}
            </C.UsuariosSection>
            {open && (
                <UserSettings
                    userRosterEdit={userRosterEdit}
                    setUserRosterEdit={setUserRosterEdit}
                    fetchData = {fetchData}
                />
            )}
        </C.UsuariosContainer>
    );
};

export default Usuarios;