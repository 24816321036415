import React, { useState } from 'react';
import * as C from './MovimentacaoDetailStyles';
import "../../../../styles/variables.css";
import SearchBar from '../../../../components/search/Search';

//componente de renderização de movimentações recebendo um objeto fixo para simular um fecth [12/08/2024]


const MovimentacaoDetail = ({ moveProcessData }) => {
    const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de busca

    const handleSearch = (term) => {
        setSearchTerm(term); // Atualiza o termo de busca
    };

    

 // Função para normalizar e remover acentos/diacríticos de uma string
    const normalizeString = (str) => {
        return str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, "")  // Remove acentos
            .replace(/ç/g, "c")               // Substitui "ç" por "c"
            .replace(/ã|á|â|à/g, "a")         // Substitui "ã", "á", "â", "à" por "a"
            .replace(/õ|ó|ô|ò/g, "o")         // Substitui "õ", "ó", "ô", "ò" por "o"
            .replace(/é|ê|è/g, "e")           // Substitui "é", "ê", "è" por "e")
            .replace(/í|ì|î/g, "i")           // Substitui "í", "ì", "î" por "i"
            .replace(/ú|ù|û/g, "u")           // Substitui "ú", "ù", "û" por "u"
            .toLowerCase();                   // Converte para minúsculas
    };

    // Filtra os dados com base no termo de busca, ignorando acentos e caracteres especiais
    const filteredData = moveProcessData.filter((item) =>
        normalizeString(item.Andamento).includes(normalizeString(searchTerm)) ||
        normalizeString(item.DataAndamento).includes(normalizeString(searchTerm))
    );
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        
        // Se a data não for válida, retorna uma string vazia ou a data original
        if (isNaN(date)) return dateString;
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses começam em 0
        const year = date.getFullYear();
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };


    return (
        <C.TimelineContainer>
            <C.MovSearchContainer>
                <SearchBar onSearch={handleSearch} placeholder="Buscar movimentações..." />
            </C.MovSearchContainer>
            {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                    <C.TimelineItem key={index}>
                        <C.Date>{formatDate(item.DataAndamento)}</C.Date>
                        <C.Description>{item.Andamento}</C.Description>
                    </C.TimelineItem>
                ))
            ) : (
                <p>Nenhuma movimentação encontrada.</p>
            )}
        </C.TimelineContainer>
    );
};

export default MovimentacaoDetail;