import React from 'react';
import * as C from '../../../pages/subPages/usuarios/UsuariosStyles';
import Button from '../../button/Button';
import { BsSearch } from "react-icons/bs";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";


//cabeçalho da tabela com adição de controlador de estado para cadastros e para filtros [12/08/2024]
// recebe um numro de colunas para fazer alinhamento  [12/08/2024]


const HeaderTable = ({ headers, data, renderRow, columns, onButtonClick, onOpenSearch, hasBtn,  activeColumn, isAscending }) => {
    return (
        <C.UsuariosTable>
            <thead>
                <C.UsuariosThead $columns={columns}>
                    {headers.map((header, idx) => (
                        <C.UsuariosTh key={idx} onClick={header.onClick}>
                            {header.label}
                            <C.ThArrow>
                            <IoMdArrowDropup 
                                    color={activeColumn === header.key && !isAscending ? 'white' : 'gray'} 
                                />
                                <IoMdArrowDropdown 
                                    color={activeColumn === header.key && isAscending ? 'white' : 'gray'} 
                                />
                            </C.ThArrow>
                        </C.UsuariosTh>
                    ))}
                        <C.UsuariosThPlus> 

                            <Button 
                                Text={<BsSearch />}
                                type="button"
                                onClick={onOpenSearch}
                            />
                        
                            {hasBtn && 
                            <Button 
                                Text="+"
                                type="button"
                                onClick={onButtonClick}
                            />
                            }
                        </C.UsuariosThPlus>


                </C.UsuariosThead>
            </thead>
            <C.UsuariosTbody>
                {data.map((item, index) => renderRow(item, index))}
            </C.UsuariosTbody>

        </C.UsuariosTable>
    );
};
export default HeaderTable;