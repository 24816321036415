import React from 'react';
import { AiFillCaretRight, AiFillCaretDown } from "react-icons/ai";
import * as C from '../AdvogadosStyle';

//componente do corpo da tabela expansiva para mais obter mais informações usuario

const TableRow = ({ advogados, index, isOpen, toggleUserInfo, handleEditClick, handleRemove, hasSubPermission}) => (
    <>
        <C.UsuariosTr onClick={() => toggleUserInfo(index)}>
            <C.UsuariosTd>{new Date(advogados.dtcadastro).toLocaleDateString('pt-BR')}</C.UsuariosTd>
            <C.UsuariosTd>{advogados.advogado}</C.UsuariosTd>
            <C.UsuariosTd>{advogados.oab}</C.UsuariosTd>
            <C.UsuariosTd>{advogados.sociedade}</C.UsuariosTd>
            <C.UsuariosTd>{advogados.observacao}</C.UsuariosTd>
            <C.UsuariosToggleIcon>
                {isOpen ? <AiFillCaretDown /> : <AiFillCaretRight />}
            </C.UsuariosToggleIcon>
        </C.UsuariosTr>
        {isOpen && (
            <C.UsuariosUserInfoTr>
                <C.UsuariosInfoTd>
                <UserDetails
                        advogados={advogados}
                        handleEditClick={() => handleEditClick(advogados)}
                        handleRemove={() => handleRemove(advogados)}
                        hasSubPermission = {hasSubPermission}
                    />
                </C.UsuariosInfoTd>
            </C.UsuariosUserInfoTr>
        )}
    </>
);

const UserDetails = ({ advogados, handleEditClick, handleRemove, hasSubPermission }) => (
    <C.UsuariosUserInfo>
        <C.UsuariosUserDetail><strong>Nome:</strong> {advogados.advogado}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>OAB/UF:</strong> {advogados.oab}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>Sociedade:</strong> {advogados.sociedade}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>Estado:</strong> {advogados.estado}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>Data de inclusão:</strong> {advogados.dtcadastro}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>Cadastrado por:</strong> {advogados.quemcadastrou}</C.UsuariosUserDetail>
        <C.UsuariosUserDetail><strong>Observações:</strong> {advogados.observacao}</C.UsuariosUserDetail>
        {hasSubPermission(3,6) &&
        <C.UsuariosEditDeleteContainer>
            <C.UsuariosEditButton onClick={handleEditClick}>Editar</C.UsuariosEditButton>
            <C.UsuariosDeleteButton onClick={handleRemove}>Deletar</C.UsuariosDeleteButton>
        </C.UsuariosEditDeleteContainer>}
    </C.UsuariosUserInfo>
);

export { TableRow, UserDetails };