import React, { useState } from "react";
import * as C from "./SigninStyle"
import { useNavigate, Link } from "react-router-dom";
import Input from "../../components/inputs/Input";
import Button from "../../components/button/Button";
import useAuth from "../../hooks/useAuth";

import "../../styles/variables.css";
import logo from "../../assets/images/LMLogo.jpeg"

//componente para renderizar a tela de login ao usuario [12/08/2024]

const Signin = () =>{

    const { signin } = useAuth();
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassoword] = useState("");
    const [error, setError] = useState("");

    // função para tratativa de login com funcionalidade de erro [12/08/2024]

    const handleLogin = async (e) =>{
        e.preventDefault();
        try {
          const success = await signin(username, password);
          if(success === true) {
            navigate('/home');
          }else if (success && success.error){
            setError(success.error)
          }else{
            setError('Tente novamente mais tarde')
        }
        
        } catch(error){
          setError('Tente novamente mais tarde');
        }
    } 

    return(
        <C.Container>
            <C.Background>
                <C.BackgroundCircle1></C.BackgroundCircle1>
                <C.BackgroundCircle2></C.BackgroundCircle2>
                <C.BackgroundCircle3></C.BackgroundCircle3>
            </C.Background>    
            <C.Content>
                <C.BodyImg>
                    <C.ContainerImg>
                        <img src={ logo } alt="logo"></img>
                    </C.ContainerImg>
                </C.BodyImg>
                <C.BodyLogin onSubmit={handleLogin}>
                    <C.Title>Entrar</C.Title>
                    <Input 
                        type="text"
                        placeholder="Username"
                        id="input_username"
                        value={username}
                        onChange={(e)=> [setUsername(e.target.value), setError("")]}
                        autocomplete={"on"}
                    />
                    <Input 
                        type="password"
                        value={password}
                        placeholder="Senha"
                        id="input_senha"
                        onChange={(e)=> [setPassoword(e.target.value), setError("")]}
                        autocomplete={"off"}
                    />
                    <C.LabelError>{error}</C.LabelError>
                        <C.Strong>
                            <Link to="/recuperar-senha">
                                <p>
                                    Esqueceu a senha?
                                </p>
                            </Link>
                        </C.Strong>
                    <Button 
                        Text="Entrar"
                        type= "submit"
                    />
                </C.BodyLogin>
            </C.Content>
        </C.Container>
    )
}


export default Signin