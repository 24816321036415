import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.625rem; /* 10px */
    height: 100vh;
    min-height: 37.5rem; /* 600px */
    min-width: 62.5rem; /* 1000px */
    position: relative;
    background: linear-gradient(50deg, var(--color-secondary) 0%, var(--color-primary) 100%) no-repeat;
    overflow: hidden;

    @media(max-width: 700px){
        min-width: 0;
    }
`;

export const Background = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 0;
`;

export const BackgroundCircle1 = styled.div`
    height: 18.75rem; /* 300px */
    width: 18.75rem; /* 300px */
    border-radius: 50%;
    background: var(--color-variant-blue);
    position: absolute;
    bottom: -9.375rem; /* -150px */
    left: -9.375rem; /* -150px */
    z-index: 1;

    @media (min-width: 87.5rem) { /* 1400px */
        height: 26.25rem; /* 420px */
        width: 26.25rem; /* 420px */
        bottom: -9.375rem; /* -150px */
        left: -9.375rem; /* -150px */
    }
`;

export const BackgroundCircle2 = styled.div`
    height: 18.75rem; /* 300px */
    width: 18.75rem; /* 300px */
    border-radius: 50%;
    background-color: var(--color-variant-blue);
    position: absolute;
    left: 50%;
    top: 1.25rem; /* 20px */
    z-index: 1;

    @media (min-width: 87.5rem) { /* 1400px */
        height: 26.25rem; /* 420px */
        width: 26.25rem; /* 420px */
        top: 0.9375rem; /* 15px */
        left: 52%;
        transform: translateX(-50%);
    }

    @media(max-width: 700px){
        display: none;
    }
`;

export const BackgroundCircle3 = styled.div`
    height: 25rem; /* 400px */
    width: 25rem; /* 400px */
    border-radius: 50%;
    background-color: var(--color-variant-blue);
    position: absolute;
    right: -1.25rem; /* -20px */
    top: -6.25rem; /* -100px */
    z-index: 1;

    @media (min-width: 87.5rem) { /* 1400px */
        height: 45.625rem; /* 730px */
        width: 45.625rem; /* 730px */
        right: 0;
        top: -12.5rem; /* -200px */
    }
`;

export const Content = styled.div`
    gap: 0.9375rem; /* 15px */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0.25rem 0.25rem 0.25rem var(--color-border); /* 4px */
    background-color: white;
    padding: 1.25rem; /* 20px */
    border-radius: 1.25rem; /* 20px */
    margin: 0 auto;
    max-width: 75rem; /* 1200px */
    background: var(--color-white);
    z-index: 10;

    @media (max-width: 700px) { /* 1400px */
        display: flex;
        flex-direction: column;
    }
`;

export const BodyLogin = styled.form`
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-direction: column;
    width: 18.75rem; /* 300px */
    height: 100%;
    text-align: right;
    background: var(--color-white);

    input{
        border: 1px solid var(--color-neutral);
        padding: 0.5rem; /* 8px */
        color: var(--color-neutral);
        border-radius: 1.25rem; /* 20px */
    }

    @media (min-width: 87.5rem) { /* 1400px */
       width: 25rem; /* 400px */
       padding-right: 2.5rem; /* 40px */
    }

    @media(max-width: 700px){
        gap: 12px
    }

    @media(max-width: 550px){
        width: 250px;
    }
`;

export const Title = styled.h2`
    font-size: 3.125rem; /* 50px */
    font-weight: 100;
    color: var(--color-neutral);
    align-self: center;
    background: var(--color-white);
`;

export const BodyImg = styled.div`
    max-width: 100%;
    position: relative;
    background: var(--color-white);
`;

export const ContainerImg = styled.div`
    position: relative;
    left: -6.25rem; /* -100px */
    background: var(--color-white);
    border: solid 0.0625rem var(--color-neutral); /* 1px */
    padding: 1.25rem; /* 20px */
    border-radius: 1.25rem; /* 20px */
    width: 26.875rem; /* 430px */
    height: 26.875rem; /* 430px */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0.25rem 0.625rem var(--color-border); /* 4px 10px */

    img{
        width: 75%;
        height: auto;
    }

    @media (min-width: 87.5rem) { /* 1400px */
       width: 37.5rem; /* 600px */
       height: 37.5rem; /* 600px */
    }

    @media (max-width: 700px) { /* 1400px */
        position: revert;
        width: 100px;
        height: 100px;

        img{
            width: 100%;
        }
    }
`;

export const LabelSignup = styled.label`
    font-size: 1.125rem; /* 18px */
    color: #676767;
    background: var(--color-white);
`;

export const LabelError = styled.p`
    font-size: 0.75rem; /* 12px */
    height: 0.9375rem; /* 15px */
    color: red;
    background: var(--color-white);
    align-self: flex-start;
`;

export const Strong = styled.strong`
    cursor: pointer;

    p{
        font-size: 0.75rem; /* 12px */
        text-decoration-line: underline;
        text-decoration-style: solid;
        color: var(--color-neutral);
        background: var(--color-white);
    }
`; 

export const StrongContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;
