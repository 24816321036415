import { createContext, useEffect, useState } from "react";
import { LoginRequest, LoginWithToken } from "../service/ServiceApi";


export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState({});




    useEffect(() => {
        //checando se o usuario esta logado [17/07/2024]
        const userToken = localStorage.getItem("user_token");
        
        if (userToken) {
            const parsedUser = JSON.parse(userToken);
            setUser(parsedUser.user);
            setPermissions(parsedUser.permissions);
        }
        setLoading(false); // Concluído o carregamento [17/07/2024]
    }, []);

    const signin = async (username, password) => {
        try{
            const res = await LoginRequest(username, password)
            if(!res.error && res.user_info.username && res.user_info.email){
                const userPermissions = res.user_info.Permissoes || {};
                const textPageInfo = sessionStorage.getItem('textPageInfo');

                if (textPageInfo) {
                    sessionStorage.setItem('Consultas', textPageInfo);
                }
                
                localStorage.setItem("user_token", 
                    JSON.stringify({
                        user: {
                            username: res.user_info.username,
                            nome: res.user_info.nome,
                            email: res.user_info.email,
                            id: res.user_info.id,
                        },
                        permissions: userPermissions
                    })
                );
                setUser({ username: res.user_info.username, email: res.user_info.email, nome: res.user_info.nome, })
                setPermissions(userPermissions);
                return true
            }else{
                return { error: res.error };
            }

        }catch(error){
            return { error: "Tente novamente mais tarde" }
        }
    };

    const userExit = () => {
        setUser(null);
        setPermissions({});
        localStorage.removeItem("user_token");
    };

    const authenticateToken = async (userID, token) => {
        try{
            const res = await LoginWithToken(userID, token)
            if(!res.error && res.user_info.username && res.user_info.email){
                const userPermissions = res.user_info.Permissoes || {};
                localStorage.setItem("user_token", 
                    JSON.stringify({
                        user: {
                            username: res.user_info.username,
                            nome: res.user_info.nome,
                            email: res.user_info.email,
                            id: res.user_info.id,
                        },
                        permissions: userPermissions
                    })
                );
                setUser({ username: res.user_info.username, email: res.user_info.email, nome: res.user_info.nome, id: res.user_info.id,})
                setPermissions(userPermissions);
                return true
            }else{
                return { error: res.error };
            }

        }catch(error){
            return { error: "Tente novamente mais tarde" }
        }
    };

    return (
        <AuthContext.Provider
            value={{ user, loading, signed: !!user, signin, userExit, permissions, authenticateToken }}
        >
            {children}
        </AuthContext.Provider>
    );
};