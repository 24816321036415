import React, { useState, useEffect } from "react";
import Input from "../../../../components/inputs/Input";
import Button from "../../../../components/button/Button";
import * as C from "./EmpresasSettingsStyles";
import { updateEmpresa, addEmpresa, Logis } from "../../../../service/ServiceApi"

const EmpresasSettings = ({ fullRoster, companyRosterEdit, setCompanyRosterEdit, fetchData }) => {
    // console.log(fullRoster)  
    const [formData, setFormData] = useState({
        empresa: "",
        cnpj: "",
        observacao: "",
        quemcadastrou: "",
        status: "",
    });

    const [notificationMessage, setNotificationMessage] = useState("");

    useEffect(() => {
        if (Object.keys(companyRosterEdit).length) {
            setFormData({
                empresa: companyRosterEdit.empresa || "",
                cnpj: companyRosterEdit.cnpj || "",
                observacao: companyRosterEdit.observacao || "",
                quemcadastrou: companyRosterEdit.quemcadastrou || "",
                status: companyRosterEdit.status || "",
            });
        }
    }, [companyRosterEdit]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
    };

    const handleSave = async () => {
        if (!formData.empresa || !formData.cnpj) {
            return;
        }
    
        const existingEmpresa = fullRoster.find(
            (company) =>
                company.cnpj.toLowerCase() === formData.cnpj.toLowerCase()
        );

        // Verifica se a empresa já existe antes de tentar salvar
        if (existingEmpresa && Object.keys(companyRosterEdit).length < 0) {
            if (existingEmpresa.ativo === 'N') {

                setNotificationMessage({
                    type: "error",
                    text: "Esta empresa já existe, mas está inativa. Deseja reativá-la?",
                    actions: [
                        {
                            label: "Reativar",
                            onClick: () => handleReactivarEmpresa(existingEmpresa)
                        },
                        {
                            label: "Cancelar",
                            onClick: () => setNotificationMessage("")
                        }
                    ]
                });
                setTimeout(() => {
                    setNotificationMessage("");
                }, 10000);
            } else {
                setNotificationMessage({
                    type: "error",
                    text: "Esta empresa já está ativa no sistema."
                });
                setTimeout(() => {
                    setNotificationMessage("");
                }, 10000);
            }
            return; 
        }
    
        const userToken = localStorage.getItem("user_token");
        let parsedUsername = '';
    
        if (userToken) {
            const parsedToken = JSON.parse(userToken);
            parsedUsername = parsedToken.user.username;
        }
    
        const updatedFormData = {
            ...formData,
            ...(Object.keys(companyRosterEdit).length ? {} : {quemcadastrou: parsedUsername}),
        };
    
        let message = '';
        let logDescription = '';
    
        try {
            if (Object.keys(companyRosterEdit).length) {
                logDescription = `Editado empresa ${updatedFormData.empresa}.`;
                message = "Informações da empresa atualizadas com sucesso!";
    
                // Atualiza a empresa e aguarda a conclusão
                await updateEmpresa(companyRosterEdit.id, updatedFormData);
                
                // Recarrega os dados da tabela após a atualização
                await fetchData(); 
    
                // Limpa os campos do formulário após a atualização bem-sucedida
                setFormData({
                    empresa: "",
                    cnpj: "",
                    observacao: "",
                    quemcadastrou: "",
                    status: "",
                });
    
                setCompanyRosterEdit({});
    
                setNotificationMessage({ type: "success", text: message });
    
                // Registrar o log da ação
                Logis({
                    route: `/api/v1/empresas`,
                    descricao: logDescription,
                    user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                });
    
                setTimeout(() => {
                    setNotificationMessage("");
                }, 5000);
            } else {
                // Adicionando uma nova empresa
                logDescription = `Nova empresa adicionada: ${updatedFormData.empresa}, CNPJ: ${updatedFormData.cnpj}`;
                message = "Nova empresa adicionada com sucesso!";
    
                const response = await addEmpresa(updatedFormData);
    
                // Verifica se a resposta contém a mensagem de erro
                if (response?.message === "Empresa já existe" && response?.status === "inativo") {
                    setNotificationMessage({
                        type: "error",
                        text: "Esta empresa já existe, mas está inativa. Deseja reativá-la?",
                        actions: [
                            {
                                label: "Reativar",
                                onClick: () => handleReactivarEmpresa(existingEmpresa)
                            },
                            {
                                label: "Cancelar",
                                onClick: () => setNotificationMessage("")
                            }
                        ]
                    });
                    return; // Não redefine os valores do formulário
                }
    
                // Recarrega os dados da tabela após adicionar a nova empresa
                await fetchData();
    
                // Limpa os campos do formulário após a adição bem-sucedida
                setFormData({
                    empresa: "",
                    cnpj: "",
                    observacao: "",
                    quemcadastrou: "",
                    status: "",
                });
    
                setNotificationMessage({ type: "success", text: message });
    
                // Registrar o log da ação
                Logis({
                    route: `/api/v1/empresas`,
                    descricao: logDescription,
                    user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                });
    
                setTimeout(() => {
                    setNotificationMessage("");
                }, 5000);
            }
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
    
            // Verifica se o erro é devido a empresa já existir
            if (error.response && error.response.data) {
                const { message, ativo } = error.response.data;
    
                // Verifica se a empresa já existe e está inativa
                if (message === "Empresa já existe" && ativo === "N") {
                    setNotificationMessage({
                        type: "error",
                        text: "Esta empresa já existe, mas está inativa. Deseja reativá-la?",
                        actions: [
                            {
                                label: "Reativar",
                                onClick: () => handleReactivarEmpresa(existingEmpresa)
                            },
                            {
                                label: "Cancelar",
                                onClick: () => setNotificationMessage("")
                            }
                        ]
                    });
                } else {
                    setNotificationMessage({ type: 'error', text: "Erro ao salvar empresa, tente novamente mais tarde." });
                }
            } else {
                setNotificationMessage({ type: 'error', text: "Erro ao salvar empresa, tente novamente mais tarde." });
            }
    
            // Registrar o log de erro
            Logis({
                route: `/api/v1/empresas`,
                descricao: `Erro ao salvar empresa ${updatedFormData.empresa}. Erro: ${error.message}`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
            });
    
            setTimeout(() => {
                setNotificationMessage("");
            }, 5000);
        }
    };
    
    
    

    const handleCancel = () => {
        setNotificationMessage("")
        setCompanyRosterEdit({});
        setFormData({
            empresa: "",
            cnpj: "",
            observacao: "",
            quemcadastrou: "",
            status: "",
        });
    };

    const handleReactivarEmpresa = async (empresa) => {
        try {
            // Atualize a empresa para 'ativa'
            const updatedEmpresa = { ...empresa, ativo: 'S' };
            await updateEmpresa(empresa.id, updatedEmpresa);
            await fetchData();

            setNotificationMessage({
                type: "success",
                text: "Empresa reativada com sucesso!"
            });

            setFormData({
                empresa: "",
                cnpj: "",
                observacao: "",
                quemcadastrou: "",
                status: "",
            });

            Logis({
                route: `/api/v1/empresas`,
                descricao: `reativando empresa ${empresa.empresa}`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                
            });

            setTimeout(() => {
                setNotificationMessage("");
            }, 5000);
        } catch (error) {
            console.error('Erro ao reativar empresa:', error);

            setNotificationMessage({
                type: "error",
                text: "Erro ao reativar empresa, tente novamente mais tarde."
            });

            Logis({
                route: `/api/v1/empresas`,
                descricao: `Erro ao reativar empresa ${empresa.empresa}`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
                
            });

            setTimeout(() => {
                setNotificationMessage("");
            }, 5000);
        }
    };

    const handleStatusChange = (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({ ...prevData, status: value }));
    };

    return (
        <C.UserSettingsContainer>
            <div>
                <C.UserSettingsButtonGroup>
                    <Button 
                        Text="Salvar"
                        type="button"
                        onClick={handleSave}
                    />
                    <Button 
                        Text="Cancelar"
                        type="button"
                        onClick={handleCancel}
                    />
                </C.UserSettingsButtonGroup>
                <C.UserSettingsTitle>Cadastro</C.UserSettingsTitle>
                <div>
                    <C.UserSettingsForm>
                        <C.UserSettingsFieldSet >
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="empresa">Empresa</C.UserSettingsLabel>
                                <Input 
                                    type="text"
                                    value={formData.empresa}
                                    id="empresa"
                                    onChange={handleChange}
                                    autocomplete="on"
                                />
                            </C.UserSettingsField>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="cnpj">CNPJ</C.UserSettingsLabel>
                                <Input 
                                    type="text"
                                    value={formData.cnpj}
                                    id="cnpj"
                                    onChange={handleChange}
                                    autocomplete="on"
                                />
                            </C.UserSettingsField>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="status">Status</C.UserSettingsLabel>
                                <C.UserSettingsSelect
                                    id="status"
                                    value={formData.status}
                                    onChange={handleStatusChange}
                                >
                                    <option value="">...</option>
                                    <option value="S">Solvente</option>
                                    <option value="I">Insolvente</option>
                                </C.UserSettingsSelect>
                            </C.UserSettingsField>
                        </C.UserSettingsFieldSet>
                        <C.UserSettingsFieldSet>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="observacao">Observação</C.UserSettingsLabel>
                                <Input 
                                    type="text"
                                    value={formData.observacao}
                                    id="observacao"
                                    onChange={handleChange}
                                    autocomplete="on"
                                />
                            </C.UserSettingsField>
                        </C.UserSettingsFieldSet>
                    </C.UserSettingsForm>
                </div>
            </div>
            {notificationMessage && (
                <C.NotificationMessage $type={notificationMessage.type}>
                    {notificationMessage.text}
                    {notificationMessage.actions && notificationMessage.actions.map((action, index) => (
                        <button key={index} onClick={action.onClick}>{action.label}</button>
                    ))}
                </C.NotificationMessage>
            )}
        </C.UserSettingsContainer>
    );
};

export default EmpresasSettings;