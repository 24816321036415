import React from "react";
import "../../../styles/variables.css"
import { LoaderSpin, LoaderContainer } from "./LoaderStyles";

// componente padrão de loader na cor padrão [12/08/2024]

const Loader = () =>{


    return(
        <LoaderContainer>
            <LoaderSpin />
        </LoaderContainer>
    );
};


export default Loader