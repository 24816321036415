import React, { useState } from "react";
import * as C from "../recover/RecoverStyles";
import "../../styles/variables.css";
import { LoginSendToken } from "../../service/ServiceApi";

import { useNavigate, Link } from "react-router-dom";
import Button from "../../components/button/Button";
import  Input from "../../components/inputs/Input";
import { IoMdUnlock } from "react-icons/io";
import useAuth from "../../hooks/useAuth";

const Recover = () => {
    const [email, setEmail] = useState("");
    const [userID, setUserID] = useState(""); 
    const navigate = useNavigate();
    const [showTokenScreen, setShowTokenScreen] = useState(false);
    const [token, setToken] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [tokenErrorMessage, setTokenErrorMessage] = useState("");
    const { authenticateToken } = useAuth();

    const handleEmailSend = async (event) => {
        event.preventDefault();

        // Validar o e-mail antes de enviar
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMessage("Por favor, insira um e-mail válido.");
            return;
        }

        // Limpar a mensagem de erro
        setErrorMessage("");

        // Função para enviar o token de recuperação
        try {
            const data = await LoginSendToken(email);
            if (data && data.message && data.user_id) {
                setUserID(data.user_id);
                setErrorMessage(data.message);
                setShowTokenScreen(true);
            } else if (data.message === "Failed to fetch") {
                setErrorMessage("Falha no Sistema. Tente novamente mais tarde");
            } else {
                setErrorMessage("Falha no Sistema.");
            }
        } catch (error) {
            setErrorMessage("Falha no sistema, tente novamente mais tarde.");
        }
    };

    const handleTokenValidation = async (event) => {
        event.preventDefault();

        if(token){
                try {
                    const success = await authenticateToken(userID, token);
                    if (success === true) {
                        navigate("/home");
                    } else if (success && success.error) {
                        setTokenErrorMessage(success.error);
                    } else {
                        setTokenErrorMessage("Falha no Sistema. Tente novamente mais tarde");
                    }
                } catch (error) {
                    setTokenErrorMessage("Falha no Sistema. Tente novamente mais tarde");
                }
            }else{
                setTokenErrorMessage("Por favor, insira o token de acesso");
            }
        }


    return (
        <C.Container>
            <C.Background>
                <C.BackgroundCircle1></C.BackgroundCircle1>
                <C.BackgroundCircle2></C.BackgroundCircle2>
                <C.BackgroundCircle3></C.BackgroundCircle3>
            </C.Background>
            <C.Content>
                <C.Img>
                    <IoMdUnlock />
                </C.Img>

                {!showTokenScreen ? (
                    <C.FormAcess onSubmit={handleEmailSend}>
                        <C.Title>
                            Esqueceu a Senha?
                        </C.Title>
                        <C.Paragraf>
                            <p>
                                Insira seu e-mail e nossa equipe vai lhe mandar um token de acesso,
                            </p>
                            <p>
                                use esse token para acessar sua conta.
                            </p>
                        </C.Paragraf>
                        <Input
                            type="email"
                            placeholder="E-mail"
                            id="input_email_recover"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {errorMessage && <C.ErrorMessage>{errorMessage}</C.ErrorMessage>}
                        <Button
                            Text="Enviar e-mail para recuperar senha"
                            type="submit"
                        />
                        <C.Strong>
                            <Link to="/login">
                                <p>
                                    Já tem uma conta?
                                </p>
                            </Link>
                        </C.Strong>
                    </C.FormAcess>
                ) : (
                    <C.FormAcess onSubmit={handleTokenValidation}>
                        <C.Title>
                            Insira o Token de Acesso
                        </C.Title>
                        <C.Paragraf>
                            <p>
                                Um token foi enviado para o seu e-mail.
                            </p>
                            <p>
                                Insira o token abaixo para acessar sua conta.
                            </p>
                        </C.Paragraf>
                        <Input
                            type="text"
                            placeholder="Token de Acesso"
                            id="input_token"
                            autocomplete = "off"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                        />
                        {tokenErrorMessage && <C.ErrorMessage>{tokenErrorMessage}</C.ErrorMessage>}
                        <Button
                            Text="Validar Token"
                            type="submit"
                        />
                    </C.FormAcess>
                )}
            </C.Content>
        </C.Container>
    );
};

export default Recover;