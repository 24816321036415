import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Signin from "../pages/signin/Signin";
import Home from "../pages/home/Home";
import useAuth from "../hooks/useAuth";
import Recover from "../pages/recover/Recover";
import Loader from "../components/loader/loaderSpin/LoaderSpin";

// Função ternária para verificação se o usuário está logado ou não [17/07/2024]

const Private = ({ Item }) => {
    const { signed, loading } = useAuth();

    if (loading) {
        //  pode ter uma tela de carregamento aqui [17/07/2024]
        return <Loader />;
    }
     
    return signed? <Item/> : <Navigate to="/login" />;
};

// Criação de routes [signin e entrada para home 17/07/2024]

const RoutesApp = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Private Item={Home} />} />
                <Route path="/home" element={<Navigate to="/" replace />} />
                <Route path="/login" element={<Signin />} />
                <Route path="/recuperar-senha" element={<Recover />} />
                <Route path="/" element={<Navigate to="/" replace />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </HashRouter>
    );
};

export default RoutesApp;