import styled from "styled-components";

export const HistoryContainer = styled.div`
  padding: 1.5rem; /* 24px */
  position: absolute;
  background: var(--color-white);
  border: 0.0625rem solid var(--color-border); /* 1px */
  border-radius: 1.25rem; /* 20px */
  height: calc(100vh - 3.75rem); /* 60px */
  min-height: 28.125rem; /* 450px */
  top: 3.5rem; /* 56px */
  right: 0.625rem; /* 10px */
  overflow-y: auto;
  width: 15.625rem; /* 250px */
  box-shadow: 0.25rem 0.25rem 0.25rem var(--color-neutral); /* 4px */
  z-index: 9;

  &::-webkit-scrollbar {
    width: 0; /* Remove a barra de rolagem no Webkit */
  }

  @media (max-width: 18.125rem) { /* 290px */
      left: 0.625rem; /* 10px */
  }
`;

export const HistoryTitle = styled.h2`
  font-size: 1.125rem; /* 18px */
  margin-bottom: 0.625rem; /* 10px */
  font-weight: 400;

  user-select: none; /* Para navegadores modernos */
  -webkit-user-select: none; /* Para Safari */
  -moz-user-select: none; /* Para Firefox */
  -ms-user-select: none; /* Para Internet Explorer/Edge */
`;

export const HistoryBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const NameTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3125rem; /* 5px */
  align-items: center;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
`;

export const HistoryName = styled.div`
  white-space: nowrap;
  font-size: 0.75rem; /* 12px */
  max-width: 66%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HistoryIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.3125rem; /* 5px */

  user-select: none; /* Para navegadores modernos */
  -webkit-user-select: none; /* Para Safari */
  -moz-user-select: none; /* Para Firefox */
  -ms-user-select: none; /* Para Internet Explorer/Edge */
  
  p {
    padding: 0.3125rem; /* 5px */
    background: var(--color-white);
    border-radius: 50%;
    font-size: 0.625rem; /* 10px */
    width: 1.25rem; /* 20px */
    height: 1.25rem; /* 20px */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const HistoryItem = styled.div`
  cursor: pointer;
  padding: 0.625rem; /* 10px */
  border: 0.0625rem solid var(--color-border); /* 1px */
  border-radius: 0.625rem; /* 10px */
  margin-bottom: 0.625rem; /* 10px */
  background: var(--color-grayBlue);
`;

export const HistoryProcessTitle = styled.p`
  font-size: 1rem; /* 16px */
  color: var(--color-black);

  user-select: none; /* Para navegadores modernos */
  -webkit-user-select: none; /* Para Safari */
  -moz-user-select: none; /* Para Firefox */
  -ms-user-select: none; /* Para Internet Explorer/Edge */
`;

export const HistoryText = styled.p`
  font-size: 0.875rem; /* 14px */
  margin-bottom: 0.625rem; /* 10px */
  white-space: nowrap;
`;

export const FetchTime = styled.p`
  font-size: 0.625rem; /* 10px */
  color: gray;
  margin-top: 0.3125rem; /* 5px */

  user-select: none; /* Para navegadores modernos */
  -webkit-user-select: none; /* Para Safari */
  -moz-user-select: none; /* Para Firefox */
  -ms-user-select: none; /* Para Internet Explorer/Edge */
`;
