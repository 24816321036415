import React from "react";
import * as C from "./ButtonStyle";
import "../../styles/variables.css";

//componete padrão de botão tamanho 100% font 600 com 16px [12/08/2024]

const Button = ({ Text, onClick, type}) =>{
    return(
        <C.Button type={type} onClick={onClick}>
            {Text}
        </C.Button>
    );
};


export default Button