import { useState, useEffect } from "react";
import { ConsultProcess, ConsultMovimentacao, ConsultCPF, ConsultTermos, Logis } from "../service/ServiceApi";

const useProcess = () => {
    const [search, setSearch] = useState("");
    const [processData, setProcessData] = useState(null);
    const [moveProcessData, setMoveProcessData] = useState(null);
    const [noResults, setNoResults] = useState("");
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false); // Flag para rastrear a inicialização
    const [activeTab, setActiveTab] = useState('processo');
    const [retCPF, setRetCPF] = useState(null);
    const userToken = localStorage.getItem("user_token");
    let user_id = "";

    // retorna o nome do usuario na tela 
    
    if (userToken) {
        user_id = JSON.parse(userToken).user.id;
    }
    

    useEffect(() => {
        const savedHistory = sessionStorage.getItem("processHistory");
        if (savedHistory) {
            setHistory(JSON.parse(savedHistory));
        }
        setIsInitialized(true); // Marcar como inicializado após carregar o histórico
    }, []);

    useEffect(() => {
        if (isInitialized) { // Só executar se estiver inicializado
            sessionStorage.setItem("processHistory", JSON.stringify(history));
        }
    }, [history, isInitialized]);

    const adjustInstanceNames = (instancias) => {
        return instancias.map(instancia => {
            if (instancia === "1 Instância") {
                return "1ª Instância";
            } else if (instancia === "2 Instância") {
                return "2ª Instância";
            } else if (instancia === "3 Instância") {
                return "Tribunal Superior";
            }
            return instancia;
        });
    };

    const handleSearch = async (term, type, shouldSaveHistory = true, bash) => {
        setLoading(true);
        setProcessData("");
        setMoveProcessData("");
        setNoResults("");
        setActiveTab("processo");

        if(!bash){
            setRetCPF(null)
        }
    
        try {
            // Primeira consulta ao processo
            const processResponse = await ConsultProcess(term, type);
            let termosResponse = null;
    
            try {
                termosResponse = await ConsultTermos(term);
            } catch (error) {
                console.error("Erro ao consultar termos:", error);
                termosResponse = null;
            }
    
            // Inicializa a variável para a segunda resposta
            let movimentacaoResponse = null;
    
            // Executa a requisição para ConsultMovimentacao apenas se o tipo for "trabalhista"
            if (type === 'trabalhista' || type === 'trabalhista/historico') {
                try {
                    movimentacaoResponse = await ConsultMovimentacao(term);
                } catch (error) {
                    console.error("Erro ao consultar movimentação:", error);
                    movimentacaoResponse = null;
                }
            }
    
            // Tratamento da resposta de processo
            if (processResponse && !processResponse.error) {
                const fetchTime = new Date().toLocaleString();
                const adjustedInstance = adjustInstanceNames(processResponse.Instancia);
    
                const processWithTime = {
                    ...processResponse,
                    Instancia: adjustedInstance,
                    fetchTime: [fetchTime],
                    Termos: termosResponse || {},
                };
    
                const history = {
                    NumProcesso: processResponse.NumProcesso,
                    name: processResponse.Ativos[0],
                    fetchTime: [fetchTime],
                };
    
                setProcessData(processWithTime);
    
                if (shouldSaveHistory) {
                    setHistory((prevHistory) => {
                        // Evita duplicar entradas
                        const existingEntryIndex = prevHistory.findIndex((entry) => entry.NumProcesso === history.NumProcesso);
                        let newHistory = [...prevHistory];
    
                        if (existingEntryIndex !== -1) {
                            // Atualiza a entrada existente, mantendo o histórico anterior
                            newHistory[existingEntryIndex] = {
                                ...newHistory[existingEntryIndex],
                                fetchTime: [...newHistory[existingEntryIndex].fetchTime, fetchTime],
                            };
                        } else {
                            // Adiciona novo processo ao histórico
                            newHistory = [...newHistory, history];
                        }
    
                        // Limita o histórico a 5 entradas
                        if (newHistory.length > 5) {
                            newHistory.shift(); // Remove o mais antigo
                        }
                        return newHistory;
                    });
                }
    
            } else if (processResponse?.error) {
                handleProcessError(processResponse.error);
            }
    
            // Tratamento da resposta de movimentação
            if (movimentacaoResponse && !movimentacaoResponse.error) {
                setMoveProcessData(movimentacaoResponse);
            } else if (movimentacaoResponse?.error) {
                handleProcessError(movimentacaoResponse.error);
            }
    
            const status = Array.isArray(processResponse?.StatusSN) && processResponse.StatusSN.length > 0 
                ? processResponse.StatusSN[0] 
                : "Não Encontrado";
    
            Logis({
                route: `/api/v1/processo/${type}`,
                descricao: `Consulta feita com o processo: ${term}, tipo: ${type}, Status ${status}`,
                user_id
            });
    
        } catch (error) {
            // Tratamento de erros mais robusto para o fetch
            console.error("Erro ao buscar processo:", error);
            setProcessData(null);
            setMoveProcessData(null);
            setNoResults("Falha no Sistema, tente novamente mais tarde.");
    
            Logis({
                route: `/api/v1/processo/${type}`,
                descricao: `Erro ao buscar processo: ${term}, tipo: ${type}.`,
                user_id
            });
    
            // Tratamento específico para o erro 'Failed to fetch'
            if (error.message === "Failed to fetch") {
                setNoResults("Erro de conexão. Verifique sua internet ou tente novamente mais tarde.");
            }
        } finally {
            // O loader é desativado apenas após todas as requisições serem concluídas
            setLoading(false);
        }
    };
    
    // Função auxiliar para lidar com diferentes erros de consulta
    const handleProcessError = (error) => {
        if (error === "Failed to fetch") {
            setNoResults("Falha na comunicação com o servidor. Tente novamente mais tarde.");
        } else if (error === "HTTP error! status: 404") {
            setNoResults("Nenhum dado encontrado nessa pesquisa.");
        } else {
            setNoResults("Erro desconhecido. Por favor, tente novamente.");
        }
    };
    

    const handleCPFSearch = async (term, type) => {
        setLoading(true);
        setProcessData("");
        setMoveProcessData("");
        setRetCPF(null);
        setNoResults("");
        setActiveTab("processo");
    
        const fetchCPFData = async (termToUse) => {
            const CPFResponse = await ConsultCPF(termToUse, type);
    
            if (CPFResponse) {
                if (Array.isArray(CPFResponse) && CPFResponse.length === 0) {
                    // Resposta vazia
                    return { data: null, error: "Nenhum dado encontrado nessa pesquisa." };
                } else if (CPFResponse.error) {
                    // Trata os erros conhecidos
                    if (CPFResponse.error === "Failed to fetch") {
                        return { data: null, error: "Falha no Sistema, tente novamente mais tarde." };
                    } else if (CPFResponse.error === "HTTP error! status: 404") {
                        return { data: null, error: "404" };
                    } else {
                        return { data: null, error: "Nenhum dado encontrado nessa pesquisa." };
                    }
                } else {
                    // Resposta válida
                    return { data: CPFResponse, error: null };
                }
            } else {
                // Caso a resposta seja null ou undefined
                return { data: null, error: "Nenhum dado encontrado nessa pesquisa." };
            }
        };
    
        try {
            // Primeira requisição com o termo original
            let { data, error } = await fetchCPFData(term);
    
            // Se a resposta for 404 e o tipo não for 'nome', faça uma nova requisição removendo a pontuação
            if (error === "404" && type !== "nome" && type !== "email") {
                const termWithoutPunctuation = term.replace(/[^\d]/g, ''); // Remove todos os caracteres não numéricos
                const secondAttempt = await fetchCPFData(termWithoutPunctuation);
    
                data = secondAttempt.data;
                error = secondAttempt.error;
            }

            let resultCount = 0;
    
            // Lida com o resultado final, seja da primeira ou da segunda tentativa
            if (data) {
                setRetCPF(data);
                resultCount = Array.isArray(data) ? data.length : 1;
            } else {
                setRetCPF(null);
                setNoResults("Nenhum dado encontrado nessa pesquisa.");
            }

            Logis({
                route: `/api/v1/${type}`,
                descricao: `Consulta de ${type}: ${term}, tipo: ${type}, encontrados: ${resultCount}`,
                user_id
            });

        } catch (error) {
            setRetCPF(null);
            setNoResults("Falha no Sistema, tente novamente mais tarde.");

            Logis({
                route: `/api/v1/${type}`,
                descricao: `Erro ao buscar consultas de ${type} feita com termo: ${term}, tipo: ${type}`,
                user_id
            });

        } finally {
            setLoading(false);
        }
    };
    
    
    const handleSearchClick = () => {
        const input = document.getElementById('input_search');
        if (input) {
            input.focus();
        }
    };
    
    const handleHistoryClick = (processo) => {

        const trabalhistaRegex = /^\d{7}-\d{2}\.\d{4}\.5\.\d{2}\.\d{4}$/; 
        const precatorioRegex = /^\d{7}-\d{2}\.\d{4}\.8\.\d{2}\.\d{4}$/;
        const bash = true

        let type ='';
    
        if (trabalhistaRegex.test(processo.NumProcesso)) {
            // Se for um número de processo trabalhista
            
            type = 'trabalhista';
        } else if (precatorioRegex.test(processo.NumProcesso)) {
            // Se for um precatório
        
            type = 'precatorio';
        }

        handleSearch(processo.NumProcesso, type, true, bash);
        setNoResults("");

    };

    const handleHistoryCover = (processo) => {
        const trabalhistaRegex = /^\d{7}-\d{2}\.\d{4}\.5\.\d{2}\.\d{4}$/; 
        const precatorioRegex = /^\d{7}-\d{2}\.\d{4}\.8\.\d{2}\.\d{4}$/;
    
        let type = '';
    
        if (trabalhistaRegex.test(processo.NumProcesso)) {
            // Se for um número de processo trabalhista
            type = 'trabalhista/historico';
        } else if (precatorioRegex.test(processo.NumProcesso)) {
            // Se for um precatório
            type = 'precatorio';
        }
    
        handleSearch(processo.NumProcesso, type, false); // Passando false para não salvar no histórico
        setNoResults("");
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    

    return {
        search,
        setSearch,
        processData,
        moveProcessData,
        noResults,
        history,
        loading,
        activeTab,
        retCPF,
        setHistory,
        handleSearch,
        handleSearchClick,
        handleHistoryClick,
        handleTabChange,
        handleCPFSearch,
        handleHistoryCover,
        
    };
};

export default useProcess;