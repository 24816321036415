import styled from "styled-components";

export const UserSettingsContainer = styled.aside`
    padding: 20px;
    background: var(--color-grayBlue);
    border-radius: 20px;
    height: 100%;
    width: 100%;
    max-width: 300px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0;
    }
`;

export const UserSettingsTitle = styled.h2`
`;

export const UserSettingsForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const UserSettingsFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const UserSettingsButtonGroup = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;

    button{
        width: 100px;
        border-radius: 5px;
    }

    button:first-child{
        background: var(--color-green);
        color: var(--color-black);
        border: 1px solid var(--color-border);
    }
`;

export const UserSettingsPrevsButton = styled.div`
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    button{
        width: 100px;
        border-radius: 5px;
        height: 30px;
    }
    svg{
        font-size: larger;
    }

`;


export const UserSettingsField = styled.div`
    margin: 10px 10px 0 0;

    input{
        padding: 10px;
        border: 1px solid var(--color-border);
        border-radius: 10px;
        margin-top: 5px;
        background: var(--color-white);
    }
`;

export const UserSettingsLabel = styled.label`
    padding-left: 10px;
`;

//

export const UserSettingsSubpermissaoGroup = styled.div`
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 12px;
`;

export const UserSettingsCheckbox = styled.input`
  width: 20px;
  height: 20px;
  padding: 8px;
  cursor: pointer;
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"%3E%3Cpolyline points="20 6 9 17 4 12"%3E%3C/polyline%3E%3C/svg%3E');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(var(--primary-color));
  }

  &:hover {
    border-color: #888;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const UserSettingsPermissoes = styled.div`
    padding: 12px;
    background-color: var(--color-white);
    border-radius: 20px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    margin-top: 20px;
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
    max-width: 300px;
`;

export const UserSettingsFieldSet = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    display: ${({ $show }) => ($show ? "block" : "none")};
    
`;

export const UserSettingsCheckboxGroup = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    input[type="checkbox"] {
        margin-right: 8px;
        width: 8px;
        height: 8px;
        cursor: pointer;
        appearance: none;
        border: 2px solid #ccc;
        border-radius: 50%;
        background-color: #fff;
        transition: background-color 0.2s ease, border-color 0.2s ease;

        &:checked {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"%3E%3Cpolyline points="20 6 9 17 4 12"%3E%3C/polyline%3E%3C/svg%3E');
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 3px var(var(--primary-color));
        }

        &:hover {
            border-color: #888;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    label {
        font-size: 16px;
        color: #555;
        cursor: pointer;
    }
`;

// Exemplo adicional para estilizar o título h3 dentro de cada grupo
export const UserSettingsGroupTitle = styled.h3`
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
`;


export const  NotificationMessage  = styled.div`
    margin-top: 10px;
    padding-left: 12px;
    color: ${({ $type }) => 
        $type === 'success' ? 'green' : 
        $type === 'error' ? 'red' : 
        'transparent'
    };
`;

