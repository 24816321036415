import React from "react";
import "../../../styles/variables.css";
import * as C from "./LoaderPulseStyles";

// componente padrão de loader na cor padrão [12/08/2024]

const Loader = () =>{


    return(
        <>
            <C.LoaderPulseContainer>
                <C.LoaderPulseMax>
                    <C.LoaderPulse />
                </C.LoaderPulseMax>
            </C.LoaderPulseContainer>
        </>
    );
};


export default Loader