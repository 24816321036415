import React, { useEffect, useState } from "react";
import "../../../styles/variables.css";
import * as C from "./UploadsStyles";
import Button from "../../../components/button/Button";
import Input from "../../../components/inputs/Input";
import { UploadListPDF, UploadPDF } from "../../../service/ServiceApi";

// import Loader from "../../../components/loader/LoaderSpin";

//componente de renderização do upload [12/08/2024]

const Uploads = () =>{
    const [file, setFile] = useState(null);
    const [error, setError] = useState("Selecione ou arraste um arquivo PDF");
    const [isValid, setIsValid] = useState(false);
    const [tableData, setTableData] = useState([]); 
    const [isUploading, setIsUploading] = useState(false);



    //função para verificar se o arquivo é um pdf [12/08/2024]

    const fetchData = async () => {
        try {
            const data = await UploadListPDF();
            
            const formattedData = Object.entries(data).map(([fileName, item], index) => ({
                id: index + 1,
                processo: fileName || "Desconhecido", 
                nome: item.user || "Usuário Desconhecido", 
                dataInclusao: new Date(item.data_create).toLocaleString("pt-BR", {
                    hour: "2-digit",
                    minute: "2-digit",
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                }), 
                dataFinalizacao: item.time_look || "", 
                progresso: item.status || "Indefinido", 
                motivo: item.motivo || "",
                status: item.statusSn || "",
            }));
        
            setTableData(formattedData);

        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    };

    useEffect(() => {
        let isMounted = true; 
    
        const fetchDataSafely = async () => {
            try {
                const data = await UploadListPDF();
                if (isMounted) {
                    const formattedData = Object.entries(data).map(([fileName, item], index) => ({
                        id: index + 1,
                        processo: fileName || "Desconhecido",
                        nome: item.user || "Usuário Desconhecido",
                        dataInclusao: new Date(item.data_create).toLocaleString("pt-BR", {
                            hour: "2-digit",
                            minute: "2-digit",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        }),
                        dataFinalizacao: item.time_look || "",
                        progresso: item.status || "Indefinido",
                        motivo: item.motivo || "",
                        status: item.statusSn || "",
                    }));
                    setTableData(formattedData);
                }
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };
    
        fetchDataSafely(); 
    
        const intervalId = setInterval(() => {
            fetchDataSafely();
        }, 5 * 60 * 1000); 
    
        return () => {
            isMounted = false; 
            clearInterval(intervalId); 
        };
    }, []);

    function formatProcesso(processo) {
        const match = processo.match(/_(\d{7}-\d{2}\.\d{4}\.\d\.\d{2}\.\d{4})/);
        const cleanedProcesso = match ? match[1] : null;
    
        if (!cleanedProcesso) {
            return processo;
        }
    
        const regexTrabalhista = /^\d{7}-\d{2}\.\d{4}\.5\.\d{2}\.\d{4}$/;
        const regexPrecatorio = /^\d{7}-\d{2}\.\d{4}\.8\.\d{2}\.\d{4}$/;
    
        if (regexTrabalhista.test(cleanedProcesso)) {
            return cleanedProcesso; 
        }
    
        if (regexPrecatorio.test(cleanedProcesso)) {
            return cleanedProcesso; 
        }
    
        return processo;
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type === "application/pdf") {
                setFile(selectedFile);
                setError(selectedFile.name);
                setIsValid(true)
            } else {
                setError("Por favor, selecione um arquivo PDF.");
                setFile(selectedFile);
                setIsValid(false)
            }
        }
    };

    //função para verificar se o arquivo  ARRASTADO é um pdf [12/08/2024]

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            if (droppedFile.type === "application/pdf") {
                setFile(droppedFile);
                setError(droppedFile.name);
                setIsValid(true)
            } else {
                setError("Por favor, selecione um arquivo PDF.");
                setFile(droppedFile);
                setIsValid(false)
            }
        }
    };

    //previne recarregamento da pagina [12/08/2024]

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    //função para enviar o arquivo [12/08/2024]


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setError("Nenhum arquivo selecionado.");
            return;
        }
        if (file.type !== "application/pdf") {
            setError("O arquivo selecionado não é um PDF.");
            return;
        }
    
        const maxFileSize = 300 * 1024 * 1024; 
        if (file.size > maxFileSize) {
            setError("O arquivo selecionado excede o tamanho máximo permitido de 300 MB.");
            return;
        }
    
        const payload = {
            user_name: JSON.parse(localStorage.getItem("user_token"))?.user?.nome,
            name: file.name,
            pdf: file,
        };
    
        setIsUploading(true);
    
        try {
            await UploadPDF(payload);
            fetchData();
    
            setTimeout(() => {
                setFile(null);
                setError("Selecione ou arraste um arquivo PDF");
                setIsValid(false);
            }, 1000);
        } catch (error) {
            console.error("Erro ao fazer upload:", error);
            setError("Erro ao fazer upload. Tente novamente.");
            setIsValid(false);
        } finally {
            setIsUploading(false);
        }
    };

    const ITEMS_PER_PAGE = 10;
    const [currentPage, setCurrentPage] = useState(0);


    const startIdx = currentPage * ITEMS_PER_PAGE;
    const endIdx = startIdx + ITEMS_PER_PAGE;
    const currentItems = tableData.slice(startIdx, endIdx);
  
    // Número total de páginas
    const totalPages = Math.ceil(tableData.length / ITEMS_PER_PAGE);
  
    // Muda para a página clicada
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const formatProgress = (progress) => {
        switch (progress) {
            case "Na Fila de Processamento":
                return "Aguardando";
            case "Aguardando o Processamento":
                return "Aguardando";
            case "Processamento Concluido":
                return "Concluído";
            case "Erro no PDF":
                return "Erro";
            case "pagina acima de 8K":
                return "pagina acima de 8K";
            case "Em Processamento":
                return "Processamento";
            case "Concluido":
                return "Concluído";    
            default:
                return progress || "Indefinido";
        }
      };


    return (
        <C.ArticleConteiner>
        <C.UploadContainer>
        <C.FormBox onSubmit={handleSubmit} onDrop={handleDrop} onDragOver={handleDragOver}>
          <C.InputFileBox>
            <Input
              type="file"
              id="pdf-upload"
              onChange={handleFileChange}
              autocomplete="off"
              className="file-input"
            />
            <C.CircleLoppingBox>
             <C.CircleLopping htmlFor="pdf-upload" $isValid={isValid}>
                <C.LabelInitBox htmlFor="pdf-upload" $isValid={isValid}>
                    { error } 
                </C.LabelInitBox>
             </C.CircleLopping>
            </C.CircleLoppingBox>
          </C.InputFileBox>
          {isUploading ? (
            <C.animateSpan>Carregando...</C.animateSpan>
            ) : (
            <Button 
                Text="Enviar" 
                type="submit" 
            />
            )}
            
        </C.FormBox>


      </C.UploadContainer>
    {tableData.length > 0 &&(
      <C.UploadContainer>
            <C.FormTable>
            <C.Header>
                <C.HeaderTitle>Ordens</C.HeaderTitle>
            </C.Header>
            <C.Envelope>
                    <C.Table>
                        <thead>
                            <tr>
                                <th>Processos</th>
                                <th>Nome</th>
                                <th>Data de Inclusão</th>
                                <th>Tempo de Execução</th>
                                <th>Progresso</th>
                                <th>Motivo</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentItems.map((item) => (
                            <tr key={item.id}>
                            <td>{item.processo ? formatProcesso(item.processo) : ""}</td>
                            <td>{item.nome || ""}</td>
                            <td>{item.dataInclusao || ""}</td>
                            <td>{item.dataFinalizacao || ""}</td>
                            <td>
                            {item.progresso && <C.ProgressIndicator $progress={item.progresso}>
                                {item.progresso ? formatProgress(item.progresso) : ""}
                                </C.ProgressIndicator>}
                            </td>
                            <td>{item.motivo || ""}</td>
                            <td>
                                {item.status && <C.Status $status={item.status}>
                                    {item.status || ""}
                                </C.Status>}
                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </C.Table>
            </C.Envelope>
                {totalPages > 1 && <C.PaginationContainer>
            {Array.from({ length: totalPages }).map((_, idx) => (
                <C.PageDot
                key={idx}
                onClick={() => handlePageChange(idx)}
                $isActive={idx === currentPage}
                />
            ))}
            </C.PaginationContainer>}

            </C.FormTable>


      </C.UploadContainer>
    )}

        </C.ArticleConteiner>
    );
  };


export default Uploads