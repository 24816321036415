import styled from "styled-components";

// Sistema UI header 3.5rem - 

export const HeaderBody = styled.header`
    min-width: 100%;
    min-height: 3.5rem; /* 56px */
    display: flex;
    gap: 1.5rem; /* 24px */
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.0625rem solid var(--color-gray); /* 1px */

    user-select: none; /* Para navegadores modernos */
    -webkit-user-select: none; /* Para Safari */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para Internet Explorer/Edge */

    svg{
        font-size: 1rem; /* 16px */
    }

    & > :last-child {
        margin-left: auto;
    }
`;

export const HamburgerBox = styled.div`
    display: flex;
    min-width: 12.5rem; /* 200px */
    justify-content: flex-end;
    align-items: center;
    padding-right: 1.625rem; /* 26px */
    color: var(--color-black);
    font-size: 1.25rem; /* 20px */
    gap: 0.625rem; /* 10px */

    @media (max-width: 33.1875rem) { /* 531px */
        padding: 0 0.3125rem; /* 5px */
        justify-content: flex-start;
        min-width: 0;
    }
`;

export const HeaderTitle = styled.h1`
    font-size: 1.875rem; /* 30px */
    text-transform: capitalize;
    font-weight: 200;

    @media (max-width: 21.875rem) { /* 330px */
        font-size: 1.125rem; /* 18px */
    }
`;

export const HamburgerIcon = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3125rem; /* 5px */

    &:hover {
        background: var(--color-gray);
        padding: 0.3125rem; /* 5px */
        border-radius: 50%;
    }
`;

export const ImgContainer = styled.div`
    display: flex;
    align-items: center;
    max-width: 6.875rem; /* 110px */
    max-height: 3.5rem; /* 56px */

    img {
        width: 100%;
        height: auto;
    }

    @media (max-width: 33.1875rem) { /* 531px */
        display: none;
        width: 4.5rem; /* 72px */
    }
`;

export const UserRef = styled.p`
    white-space: nowrap;
    text-transform: capitalize;
    
    @media (max-width: 41.5625rem) { /* 665px */
        display: none;
    }
`;

export const UserBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 3.5rem; /* 56px */
    padding-right: 1.25rem; /* 20px */
    gap: 0.9375rem; /* 15px */

    button {
        padding: 0.3125rem 0.9375rem; /* 5px 15px */
        border-radius: 1.25rem; /* 20px */
        width: 4.25rem; /* 68px */
        font-size: 0.875rem; /* 14px */
    }

    @media (max-width: 20.625rem) { /* 330px */
        gap: 0.625rem; /* 10px */
        padding-right: 0.625rem; /* 10px */
        button {
            font-size: 0.75rem; /* 12px */
            width: 3.25rem; /* 52px */
        }
    }
`;
